import React from "react";
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import {Row} from "../../../../core/styles";
import {IonCol, IonFooter, IonRow, IonText} from "@ionic/react";
import {colors} from "../../../business/constants/global";
import {Heading} from "../../../components/primitive/heading";
import {SuccessLogo} from "../../../components/primitive/successLogo";
import {UniversalButton} from "../../../components/primitive/buttons";

export const PhoneCreatedSuccessModal = ({setIsOpenSuccessModal}) => {
  return (
    <>
      <Row className={"flex-center"}>
        <AddIcCallIcon color={'primary'} style={{fontSize: 80}}/>
      </Row>

      <Heading hasSuccessLogo={false} text={'Thank you!'}/>

      <IonRow className='ion-justify-content-center' style={{fontSize: 22}}>
        <IonCol className="flex-center" size="auto">Cell phone 5555551234</IonCol>
      </IonRow>
      <IonRow className='ion-justify-content-center' style={{fontSize: 22}}>
        <IonCol className="flex-center" size="auto">has been added to your</IonCol>
      </IonRow>
      <IonRow className='ion-justify-content-center' style={{fontSize: 22}}>
        <IonCol className="flex-center" size="auto">profile</IonCol>
      </IonRow>

      <IonFooter>
        <UniversalButton
          value={'BUTTON.CONTINUE'}
          onClick={() => setIsOpenSuccessModal(false)}
          disabled={false}
        />
      </IonFooter>
    </>
  )
}
