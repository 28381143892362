import { INPUT_TYPES } from "../../../../core/business/enums";
import InputRowModel from "../../../../core/business/models/inputRow";
import { input } from "../../../../core/business/helpers";

export const PASSWORD_FORM: Array<InputRowModel> = [
  {
    items: [
      {
        ...INPUT_TYPES.PASSWORD,
        validation: [
          input.requiredValidation,
          input.passwordLengthValidation,
          input.hasUpperCaseValidation,
          input.hasLowerCaseValidation,
          input.hasNumberValidation,
        ],
        gridSize: 12
      }
    ]
  }
];
