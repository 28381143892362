import React from 'react';
import {IonText} from "@ionic/react";
import LogoutIcon from "@mui/icons-material/Logout";

interface Props {
  logout: Function
}

export const LogOutButton: React.FC<Props> = ({logout}) => (
  <IonText className="header-logout-btn"
           onClick={() => logout({returnTo: window.location.origin})}>
    <LogoutIcon/> Logout
  </IonText>
)
