import React from "react";
import {makeStyles} from '@material-ui/core/styles';
import {ICON_QUESTION} from "../../../images";
import {questionMarkStyles} from "./style";
import {IonImg} from "@ionic/react";

interface QuestionMarkIconProps {
  newStyles?: any;
  onClick?: any;
}
export const QuestionMarkIcon: React.FC<QuestionMarkIconProps> = ({ newStyles = {}, onClick = () => {} }) => {

  const classes = makeStyles(() => ({
    ...questionMarkStyles,
    iconQuestion: {
      ...questionMarkStyles.iconQuestion,
      ...newStyles
    }
  }))()

  return <>&nbsp;<IonImg onClick={onClick} className={classes.iconQuestion} src={ ICON_QUESTION }/></>
}
