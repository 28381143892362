import {
  LOADING_BY_TYPE_INIT_STATE,
  SET_LOADING_BY_MIDDLE_TYPE,
  CLEAR_LOADING_BY_MIDDLE_TYPE,
  FAILURE_LOADING_BY_MIDDLE_TYPE,
  LoadingByTypesActionTypes,
  LoadingByTypeReducerType
} from "./types";

export default function loadingByTypeReducer(
  state: LoadingByTypeReducerType = LOADING_BY_TYPE_INIT_STATE,
  action: LoadingByTypesActionTypes,
): LoadingByTypeReducerType {
  switch (action.type) {
    case SET_LOADING_BY_MIDDLE_TYPE:
      return {
        loading: true,
        modalData: action.loadingData.modalData
      };
    case CLEAR_LOADING_BY_MIDDLE_TYPE:
      return {
        loading: false,
        modalData: null
      };
    case FAILURE_LOADING_BY_MIDDLE_TYPE:
      return {
        loading: false,
        modalData: null
      };
    default:
      return state;
  }
}
