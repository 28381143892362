import React, {useState} from "react";
import {style} from "./style";
import {makeStyles} from "@material-ui/core/styles";
import {getDate} from "../../../business/helpers/date";
import {QuestionMarkIcon} from "../icons";
import {InformationModal} from "../modals";
import {IonCol, IonRow, IonText} from "@ionic/react";
import {colors} from "../../../business/constants/global";
import {useMediaQuery} from "@mui/material";
import {ENUMS} from "../../../../core/styles";

export const Ho4StatusSection = ({resident, policyNumber, color}) => {

  const isMobile = useMediaQuery(`(max-width:${ENUMS.MOBILE_WIDTH})`);

  const [isOpen, setIsOpen] = useState(false);

  // @ts-ignore
  const classes = makeStyles(() => style(colors.cyan))();

  return (
    <>
      <IonRow>
        <IonCol>
          <h2 className={classes.mediumTitle + ' flex-center'}>{resident?.insurance_company_selected}
            {!policyNumber &&
              <QuestionMarkIcon onClick={() => setIsOpen(true)}/>}</h2>
          <br/>
          {policyNumber &&
            <IonText className="flex-center">
              <h2 className={classes.mediumTitle}>#({policyNumber})</h2>
              <QuestionMarkIcon onClick={() => setIsOpen(true)}/>
            </IonText>}
        </IonCol>
      </IonRow>
      <IonRow>
        {color !== colors.yellow && (
          <IonCol>
            <p className={classes.paragraph}>Expiration Date</p>
            <IonText className="ion-text-center flex-center">
              <h3 className={classes.mediumTitle}>{getDate(resident?.policy_expiration_date)}</h3>
              {color === 'EXPIRING' && <p>Expiring in 5 days</p>}
            </IonText>
          </IonCol>
        )}
      </IonRow>
      <InformationModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        headerText={'What is an insurance Company?'}
        contextText={
          <p>
            The company name of your insurance provider should be entered here.
            This is where you purchased renters insurance from,
            and the name can be found on your policy documents.
          </p>
        }
      />
    </>
  )
}
