import {EMAIL_VERIFICATION_ROUTE} from "./routes";

export const PATHS = {
  ACCOUNT_SETTINGS: "/account-settings",
  ACCOUNT_SETTINGS_EDIT: "/edit-settings",
  ADD_YOUR_NAME: "/add-your-name",
  ADDRESS_CONFIRMATION: "/address-confirmation",
  CHANGE_INSURANCE_PROVIDER: "/change-insurance-provider",
  CHAT: "/chat",
  CHOOSE_INSURANCE: "/choose-insurance",
  CONTACT_INFORMATION: "/contact-information",
  COMMUNITY_INFORMATION: "/community-information",
  COVERAGE: "/coverage",
  EMAIL_VERIFICATION: "/email-verification",
  EMAIL_LOGIN: "/email-login",
  FULL_NAME_INFORMATION: "/full-name-information",
  MOVE_IN_DATE_INFORMATION: "/move-in-date-information",
  MY_COVERAGE: "/my-coverage",
  PASSWORD_APPROVED: "/password-approved",
  PASSWORD_CONFIRMATION: "/password-confirmation",
  PERSONAL_ACCESS: "/personal-access",
  POLICY_INFORMATION_SENT: "/policy-information-sent",
  POLICY_SENT: "/policy-sent",
  SWITCH_TO_COVERAGE_NEW_CONFIRMATION: "/switch-to-new-coverage-confirmation",
  USER_INFORMATION: "/user-information",
  PASSWORD_LESS_LOGIN: "/password-less-login",
  PHONE_VERIFICATION: "/phone-verification",
  PREFERENCES: "/preferences",
  // WAIVER_INTRODUCTION: "/waiver-introduction",
};
