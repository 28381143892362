const stepContent = {
  listStyleType: 'none',
  paddingLeft: 0,
  display: 'flex',
  textAlign: 'center',
  paddingTop: 15,
  position: 'relative',
  justifyContent: 'center',
  '&::before': {
    content: '" "',
    height: 2,
    background: '#D8D8D8',
    position: 'absolute',
    left: '50%',
    top: 0,
    transform: 'translateX(-50%)'
  }
}

const li = {
  width: 85,
  padding: '0 10',
  color: 'rgba(45, 45, 45, 0.3)',
  fontSize: 12,
  position: 'relative',
  '&::before': {
    content: '" "',
    position: 'absolute',
    width: 8,
    height: 8,
    borderRadius: '50%',
    top: -21,
    background: '#2D2D2D',
    left: '50%',
    marginLeft: -7,
    border: '3px solid #f8f8f8',
    zIndex: 1,
  }
}

const activeStep = {
  ...li,
  color: '#2D2D2D',
  fontWeight: 700,
  '&::after': {
    content: '" "',
    position: 'absolute',
    width: 18,
    height: 18,
    borderRadius: '50%',
    top: -23,
    background: '#43CED3',
    left: '50%',
    marginLeft: -9,
    zIndex: 0,
  }
}

const prevStep = {
  ...li,
  '&::before': {
    ...li['&::before'],
    background: '#43CED3'
  }
}

const stepLine = {
  position: 'relative',
  display: 'block',
  '&::after': {
    content: '" "',
    position: 'absolute',
    top: -15,
    height: 2,
    width: 85,
    background: '#D8D8D8',
    left: 37
  }
};

export const stepStyle = {
  stepContent,
  stepLine,
  activeStep,
  prevStep,
  nextStep: li
}
