import { COLOR } from '../../styles';
import { configs } from '../../../configs';
import { SORT } from '../enums';

export interface TableModel {
  dataSource: DataSourceModel;
  styles: TableStylesModel;
  filter?: any;
  innerConfig?: TableModel;
}

export interface TableStylesModel {
  responsive?: boolean;
  color?: string;
  isStripped?: boolean;
  borderColor?: string;
  cellPadding?: string;
  minWidth?: number;
  maxWidth?: number;
  headerHeight?: number;
  rowHeight?: number;
  checkWidth?: number;
  fontSize?: string;
  fontColor?: string;
  additionalHeight?: number;
  disableSort?: boolean;
  setRowClass?: Function;
  groupBy?: string;
  groupColor?: string;
}

export interface DataSourceModel {
  endpoint?: string;
  method?: string;
  mockData?: any;
  fixedData?: any;
  defaultParam?: any;
  isPublic?: boolean;
  pageSize?: number;
  defaultPage?: number;
  defaultSortKey?: string;
  defaultSortOrder?: SORT.types;
  shouldVirtualize?: boolean;
  deriveToModel?: Function;
  autoRefreshInterval?: number;
  onClickRow?: (r) => void;
  draggable?: boolean;
  hideLoadingIndicator?: boolean;
  hideInitialLoading?: boolean;
  virtualized?: {
    rowHeight: number;
    headerHeight: number;
    threshold: number;
    checkWidth?: number;
    additionalHeight?: number;
  };
}

export interface TableDisplayModel {
  totalDataSize: number;
  pageSize?: number;
  page?: number;
  sortKey?: string;
  sortOrder?: SORT.types;
  isSelectable?: boolean;
}

export const defaultStyles: TableStylesModel = {
  responsive: false,
  color: COLOR.PRIMARY,
  isStripped: true,
  cellPadding: 'inherit',
  borderColor: 'var(--ion-border-color)',
  minWidth: 800,
};

export const defaultDataSource: DataSourceModel = {
  endpoint: '',
  method: 'POST',
  isPublic: false,
  pageSize: configs.display.pageSize || 20,
  defaultPage: 1,
  defaultSortOrder: SORT.ASC,
  hideLoadingIndicator: false,
  hideInitialLoading: false,
  shouldVirtualize: false,
  virtualized: {
    rowHeight: 36,
    headerHeight: 60,
    checkWidth: 60,
    threshold: 10,
    additionalHeight: 40,
  },
};

export const defaultDisplay: TableDisplayModel = {
  totalDataSize: 0,
  page: 1,
  pageSize: configs.display.pageSize || 20,
  sortKey: '',
  sortOrder: SORT.ASC,
  isSelectable: false,
};

export const defaultTable = {
  dataSource: defaultDataSource,
  styles: defaultStyles,
};

export const groupTable = (data, colName) => {
  const cols: any = [];
  const groupedArr = data.reduce((acc, row) => {
    const colValue = row[colName];
    const index = cols.indexOf(colValue);
    if (index >= 0) acc[index].push(row);
    else {
      acc.push([row]);
      cols.push(colValue);
    }
    return acc;
  }, []);

  return groupedArr.reduce((acc, row) => [...acc, { grouped: true, name: row[0][colName] }, ...row], []);
};
