import React, {useRef} from "react";
import {enterAnimation, leaveAnimation} from "../helpers";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonImg,
  IonItem,
  IonList,
  IonModal,
  IonToolbar
} from "@ionic/react";

export const ImageModal = ({fileUrl, isOpen, setIsOpen}) => (
  <IonModal
    id="image-modal"
    isOpen={isOpen}
    ref={useRef<HTMLIonModalElement>(null)}
    trigger="open-modal"
    enterAnimation={enterAnimation}
    leaveAnimation={leaveAnimation}
    onWillDismiss={() => setIsOpen(false)}
  >
    <IonContent>
      <IonToolbar>
        <IonButtons slot="end">
          <IonButton onClick={() => setIsOpen(false)}>Close</IonButton>
        </IonButtons>
      </IonToolbar>
      <IonList>
        <IonItem>
          <IonImg class="icon-photo" src={fileUrl}/>
        </IonItem>
      </IonList>
    </IonContent>
  </IonModal>
);
