import React from "react";
import {IonCol, IonRow, IonText} from "@ionic/react";

export const PasswordInformation: React.FC<any> = ({ errors }) => {

  const errorMessages = errors.map(error => error.message);

  const validateError = (messages) => errorMessages.includes(messages) ? "danger" : "secondary";

  return (
    <IonRow>
      <IonCol className="logo-box">
        <p>Password must contain the following:</p>
        <ul className="password-characters-check">
          <li>
            <IonText color={validateError("INVALID_PASSWORD")}>at least 8 characters</IonText>
          </li>
          <li>
            <IonText color={validateError("UPPERCASE_LETTERS")}>at least one uppercase letter (A-Z)</IonText>
          </li>
          <li>
            <IonText color={validateError("LOWERCASE_LETTERS")}>at least one lowercase letter (A-Z)</IonText>
          </li>
          <li>
            <IonText color={validateError("NUMBER")}>at least one number (0-9)</IonText>
          </li>
        </ul>
      </IonCol>
    </IonRow>
  );
};
