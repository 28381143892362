import { PolicyDocument } from "../../../models/policyDocument";

export const FETCH_POLICY_DOCUMENT_SUCCESS = 'FETCH_POLICY_DOCUMENT_SUCCESS';
export const FETCH_POLICY_DOCUMENT_FAILURE = 'FETCH_POLICY_DOCUMENT_FAILURE';
export const SAVE_POLICY_DOCUMENT_SUCCESS = 'SAVE_POLICY_DOCUMENT_SUCCESS';
export const SAVE_POLICY_DOCUMENT_FAILURE = 'SAVE_POLICY_DOCUMENT_FAILURE';
export const CLEAN_POLICY_DOCUMENT = 'CLEAN_POLICY_DOCUMENT';

// Action Types
export interface FetchPolicyDocumentSuccessType {
  type: typeof FETCH_POLICY_DOCUMENT_SUCCESS;
  policyDocument: PolicyDocument | null;
}

export interface FetchPolicyDocumentFailureType {
  type: typeof FETCH_POLICY_DOCUMENT_FAILURE;
  err: string;
}

export interface SavePolicyDocumentSuccessType {
  type: typeof SAVE_POLICY_DOCUMENT_SUCCESS;
  policyDocument: PolicyDocument;
}

export interface SavePolicyDocumentFailureType {
  type: typeof SAVE_POLICY_DOCUMENT_FAILURE;
  err: string;
}

export interface CleanPolicyDocumentType {
  type: typeof CLEAN_POLICY_DOCUMENT;
}

export type PolicyDocumentReducerType = PolicyDocument | null;

export type PolicyDocumentActionTypes =
  | FetchPolicyDocumentSuccessType
  | FetchPolicyDocumentFailureType
  | SavePolicyDocumentSuccessType
  | SavePolicyDocumentFailureType
  | CleanPolicyDocumentType;

export const POLICY_DOCUMENT_INIT_STATE: PolicyDocumentReducerType = null
