import moment from "moment/moment";

export const getFormData = data => ({
  pr_id: data?.pr_id || '',
  u_id: data?.u_id || '',
  first_name: data?.first_name || '',
  last_name: data?.last_name || '',
  phone: data?.phone || '',
  email: data?.email || '',
  move_in_date: data?.move_in_date || moment().format('MM/DD/YYYY'),
  i_want_to_receive_text_messages: data?.i_want_to_receive_text_messages || 't',
  sms_opt_in: true,
})
