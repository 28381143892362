import ConfigsModel from "../../../models/configs";

export const ADD_LOGIN_BUTTON = 'ADD_LOGIN_BUTTON';
export const REMOVE_LOGIN_BUTTON = 'REMOVE_LOGIN_BUTTON';
export const ADD_LOGOUT_BUTTON = 'ADD_LOGOUT_BUTTON';
export const REMOVE_LOGOUT_BUTTON = 'REMOVE_LOGOUT_BUTTON';

export interface AddLoginButtonType {
  type: typeof ADD_LOGIN_BUTTON;
  configs: ConfigsModel | null
}

export interface RemoveLoginButtonType {
  type: typeof REMOVE_LOGIN_BUTTON;
  configs: ConfigsModel | null
}

export interface AddLogoutButtonType {
  type: typeof ADD_LOGOUT_BUTTON;
  configs: ConfigsModel | null
}

export interface RemoveLogoutButtonType {
  type: typeof REMOVE_LOGOUT_BUTTON;
  configs: ConfigsModel | null
}

export type ConfigsReducerType = ConfigsModel | null

export type ConfigsActionTypes =
  | AddLoginButtonType
  | RemoveLoginButtonType
  | AddLogoutButtonType
  | RemoveLogoutButtonType

export const CONFIGS_INIT_STATE = {
  hasLoginButton: false,
  hasLogoutButton: false,
  hasPrevPageIcon: false,
}
