import { Action, AnyAction, Middleware } from 'redux'

export const SET_LOADING_BY_MIDDLE_TYPE = 'SET_LOADING_BY_MIDDLE_TYPE';
export const CLEAR_LOADING_BY_MIDDLE_TYPE = 'CLEAR_LOADING_BY_MIDDLE_TYPE';
export const FAILURE_LOADING_BY_MIDDLE_TYPE = 'FAILURE_LOADING_BY_MIDDLE_TYPE';

// Action Types
export interface setLoadingByMiddleType {
  type: typeof SET_LOADING_BY_MIDDLE_TYPE;
  loadingData: {
    modalData: any
    loading: boolean
  };
}

export interface clearLoadingByMiddleType {
  type: typeof CLEAR_LOADING_BY_MIDDLE_TYPE;
  loadingData: {
    loading: true
    modalData: null,
  };
}

export interface failureLoadingByMiddleType {
  type: typeof FAILURE_LOADING_BY_MIDDLE_TYPE;
  err: string;
}

export type LoadingByTypeReducerType = {
  loading: boolean,
  modalData: any
};

export type LoadingByTypesActionTypes =
  | setLoadingByMiddleType
  | clearLoadingByMiddleType
  | failureLoadingByMiddleType;

export const LOADING_BY_TYPE_INIT_STATE = {
  loading: false,
  modalData: null
}
