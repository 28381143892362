import RouteModel from '../../../core/business/models/route';
import {
  AccountSettings,
  AccountSettingsEdit,
  AddYourName,
  AddressConfirmation,
  ChangeInsuranceProvider,
  Chat,
  ChooseInsurance,
  CommunityInformation,
  ContactInformation,
  Coverage,
  EmailLogin,
  EmailVerification,
  FullNameInformation,
  MoveInDateInformation,
  MyCoverage,
  PasswordApproved,
  PasswordConfirmation,
  PersonalAccess,
  PolicyInformationSent,
  PolicySent,
  SwitchToCoverageConfirmation,
  UserInformation,
  PasswordLessLogin,
  PhoneVerification, Preferences,
  // WaiverIntroduction,
} from "../../screens";
import {PATHS} from "./paths";

export const ACCOUNT_SETTINGS_ROUTE = {
  path: PATHS.ACCOUNT_SETTINGS,
  component: AccountSettings,
};

export const ACCOUNT_SETTINGS_EDIT_ROUTE = {
  path: PATHS.ACCOUNT_SETTINGS_EDIT,
  component: AccountSettingsEdit,
};

export const ADDRESS_CONFIRMATION_ROUTE = {
  param: ':id',
  path: PATHS.ADDRESS_CONFIRMATION,
  component: AddressConfirmation,
};

export const CHANGE_INSURANCE_PROVIDER_ROUTE = {
  path: PATHS.CHANGE_INSURANCE_PROVIDER,
  component: ChangeInsuranceProvider,
};

export const CHAT_ROUTE = {
  path: PATHS.CHAT,
  param: ':id',
  component: Chat,
};

export const CHOOSE_INSURANCE_ROUTE = {
  path: PATHS.CHOOSE_INSURANCE,
  component: ChooseInsurance,
};

export const COVERAGE_ROUTE = {
  path: PATHS.COVERAGE,
  component: Coverage,
};

export const MY_COVERAGE_ROUTE = {
  path: PATHS.MY_COVERAGE,
  component: MyCoverage,
};

export const PASSWORD_APPROVED_ROUTE = {
  path: PATHS.PASSWORD_APPROVED,
  component: PasswordApproved,
};

// export const PASSWORD_CONFIRMATION_ROUTE = {
//   path: PATHS.PASSWORD_CONFIRMATION,
//   component: PasswordConfirmation
// };

export const PERSONAL_ACCESS_ROUTE = {
  path: PATHS.PERSONAL_ACCESS,
  component: PersonalAccess,
};

export const CONTACT_INFORMATION_ROUTE = {
  path: PATHS.CONTACT_INFORMATION,
  component: ContactInformation,
};

export const FULL_NAME_INFORMATION_ROUTE = {
  path: PATHS.FULL_NAME_INFORMATION,
  component: FullNameInformation,
};

export const ADD_YOUR_NAME_ROUTE = {
  path: PATHS.ADD_YOUR_NAME,
  component: AddYourName,
};

export const COMMUNITY_INFORMATION_ROUTE = {
  path: PATHS.COMMUNITY_INFORMATION,
  component: CommunityInformation,
};

export const EMAIL_VERIFICATION_ROUTE = {
  path: PATHS.EMAIL_VERIFICATION,
  component: EmailVerification,
};

export const EMAIL_LOGIN_ROUTE = {
  path: PATHS.EMAIL_LOGIN,
  component: EmailLogin,
};

export const MOVE_IN_DATE_INFORMATION_ROUTE = {
  path: PATHS.MOVE_IN_DATE_INFORMATION,
  component: MoveInDateInformation,
};

export const POLICY_INFORMATION_SENT_ROUTE = {
  path: PATHS.POLICY_INFORMATION_SENT,
  component: PolicyInformationSent
};

export const POLICY_SENT_ROUTE = {
  path: PATHS.POLICY_SENT,
  component: PolicySent,
};

export const PHONE_VERIFICATION_ROUTE = {
  path: PATHS.PHONE_VERIFICATION,
  component: PhoneVerification,
};

export const SWITCH_TO_COVERAGE_CONFIRMATION_ROUTE = {
  path: PATHS.SWITCH_TO_COVERAGE_NEW_CONFIRMATION,
  component: SwitchToCoverageConfirmation,
};

export const USER_INFORMATION_ROUTE = {
  path: PATHS.USER_INFORMATION,
  component: UserInformation,
};

export const PASSWORD_LESS_LOGIN_ROUTE = {
  path: PATHS.PASSWORD_LESS_LOGIN,
  component: PasswordLessLogin,
};

export const PREFERENCES_ROUTE = {
  path: PATHS.PREFERENCES,
  component: Preferences,
};

// export const WAIVER_INTRODUCTION_ROUTE = {
//   path: PATHS.WAIVER_INTRODUCTION,
//   component: WaiverIntroduction,
// };

export const PUBLIC_ROUTES: Array<RouteModel> = [
  // WAIVER_INTRODUCTION_ROUTE,
  ADDRESS_CONFIRMATION_ROUTE,
  ADD_YOUR_NAME_ROUTE,
  CHANGE_INSURANCE_PROVIDER_ROUTE,
  CHAT_ROUTE,
  CHOOSE_INSURANCE_ROUTE,
  COMMUNITY_INFORMATION_ROUTE,
  CONTACT_INFORMATION_ROUTE,
  COVERAGE_ROUTE,
  EMAIL_LOGIN_ROUTE,
  EMAIL_VERIFICATION_ROUTE,
  MOVE_IN_DATE_INFORMATION_ROUTE,
  PASSWORD_APPROVED_ROUTE,
  // PASSWORD_CONFIRMATION_ROUTE,
  PASSWORD_LESS_LOGIN_ROUTE,
  PERSONAL_ACCESS_ROUTE,
  // PHONE_VERIFICATION_ROUTE,
  POLICY_INFORMATION_SENT_ROUTE,
];

export const PRIVATE_ROUTES: Array<RouteModel> = [
  ACCOUNT_SETTINGS_ROUTE,
  ACCOUNT_SETTINGS_EDIT_ROUTE,
  MY_COVERAGE_ROUTE,
  POLICY_SENT_ROUTE,
  PREFERENCES_ROUTE,
  USER_INFORMATION_ROUTE,
  SWITCH_TO_COVERAGE_CONFIRMATION_ROUTE,
];
