import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, AlertProps } from '@mui/material';
import { toasterActions } from '../../../business/redux/toaster';
import { ToastModel } from '../../../business/redux/toaster/types';

import { SnackbarWrapper } from './styles';
import intl from '../../../intl';

const ToasterManager: React.FC = () => {
  const dispatch = useDispatch();
  const toaster = useSelector((state: any) => state.toaster);

  const { success, warning, danger } = toaster;

  const resetToastSuccess = () => {
    dispatch(toasterActions.resetToastSuccess());
  };
  const resetToastWarning = () => {
    dispatch(toasterActions.resetToastWarning());
  };
  const resetToastDanger = () => {
    dispatch(toasterActions.resetToastDanger());
  };

  const generateSnackbar = (toast: ToastModel, type: AlertProps['color']) => {
    const onClose =
      type === 'success'
        ? resetToastSuccess
        : type === 'warning'
        ? resetToastWarning
        : type === 'error'
        ? resetToastDanger
        : undefined;
    const text = intl(toast.text) || intl(toast.key);
    return (
      <SnackbarWrapper
        anchorOrigin={{ horizontal: 'center', vertical: toast.position === 'TOP' ? 'top' : 'bottom' }}
        open={toast.isOpen}
        autoHideDuration={toast.autoClose ? toast.duration : undefined}
        onClose={onClose}>
        <Alert
          variant="filled"
          onClose={!toast.autoClose ? onClose : undefined}
          severity={type}
          className={toast.className}>
          {text}
        </Alert>
      </SnackbarWrapper>
    );
  };

  return (
    <>
      {generateSnackbar(success, 'success')}
      {generateSnackbar(warning, 'warning')}
      {generateSnackbar(danger, 'error')}
    </>
  );
};

export default ToasterManager;
