import _ from 'lodash';
import React from 'react';
import { FormControlLabel, FormHelperText, Switch as MuiSwitch } from '@mui/material';
import { FastField, Field } from 'formik';

import intl from '../../../intl';

interface SwitchProps {
  icon?: string;
  label?: string;
  labelText?: string;
  name: string;
  disabled?: boolean;
  readonly?: boolean;
  position?: string;
  formik?: any;
  slot?: string;
  value?: any;
  handleChange?: (e) => void;
  disableFastField?: boolean;
}

const defaultProps: SwitchProps = {
  icon: '',
  label: '',
  labelText: '',
  name: '',
  disabled: false,
  readonly: false,
  formik: {},
  slot: 'end',
  value: 'on',
  disableFastField: false,
  handleChange: () => {},
};

const Switch: React.FC<SwitchProps> = ({
  icon,
  label,
  labelText,
  name,
  disabled,
  readonly,
  position,
  formik,
  slot,
  value,
  disableFastField,
  handleChange,
  ...props
}) => {
  const onChange = (e) => {
    if (formik.setFieldValue) formik.setFieldValue(name, e.target.checked);
    if (handleChange) handleChange(e);
  };

  const errorKey = _.get(formik.errors, name);
  const isTouched = _.get(formik.touched, name) !== undefined;
  const hasError = isTouched && errorKey !== undefined;
  const errorMsg = hasError ? intl(`INPUT.ERROR.${errorKey}`) : undefined;

  const FormField = disableFastField ? Field : FastField;

  return (
    <>
      <FormField name={name}>
        {({ form }) => {
          return (
            <>
              <FormControlLabel
                control={
                  <MuiSwitch
                    {...props}
                    name={name}
                    disabled={disabled}
                    checked={!!_.get(form.values, name)}
                    onChange={onChange}
                    value={value}
                    color="primary"
                  />
                }
                label={labelText || intl(label)}
              />
              <FormHelperText>{errorMsg}</FormHelperText>
            </>
          );
        }}
      </FormField>
    </>
  );
};

Switch.defaultProps = defaultProps;

export default Switch;
