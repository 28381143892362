import { SPACE } from '../../../styles';
import { Popover } from '@mui/material';
import styled from 'styled-components';

export const PopoverWrapper = styled(Popover)`
  &&.backdrop {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .pop-container {
    padding: ${SPACE.SMALL};
  }

  button.left .MuiButton-label {
    justify-content: flex-start;
  }
`;
