import { Action, AnyAction, Middleware } from 'redux'
import { UnitModel, UnitsByPrIdResponseModel } from "../../../models/unit";

export const FETCH_UNITS_BY_PR_ID_SUCCESS = 'FETCH_UNITS_BY_PR_ID_SUCCESS';
export const FETCH_UNITS_BY_PR_ID_FAILURE = 'FETCH_UNITS_BY_PR_ID_FAILURE';

// Action Types
export interface FetchUnitsByPrIdSuccessType {
  type: typeof FETCH_UNITS_BY_PR_ID_SUCCESS;
  units: { success: boolean, units: Array<UnitModel> } | null;
}

export interface FetchUnitsByPrIdFailureType {property
  type: typeof FETCH_UNITS_BY_PR_ID_FAILURE;
  err: string;
  units: { success: boolean, units: [] };
}

export type UnitsByPrIdReducerType = UnitsByPrIdResponseModel | null;

export type UnitsByPrIdActionTypes = FetchUnitsByPrIdSuccessType | FetchUnitsByPrIdFailureType;

export const UNITS_BY_PR_ID_INIT_STATE = null




export interface ThunkDispatch<
  State,
  ExtraThunkArg,
  BasicAction extends Action
  > {
    <ReturnType>(
    thunkAction: ThunkAction<ReturnType, State, ExtraThunkArg, BasicAction>
  ): ReturnType
    <Action extends BasicAction>(action: Action): Action
    <ReturnType, Action extends BasicAction>(
    action: Action | ThunkAction<ReturnType, State, ExtraThunkArg, BasicAction>
  ): Action | ReturnType
}
export type ThunkAction<
  ReturnType,
  State,
  ExtraThunkArg,
  BasicAction extends Action
  > = (
  dispatch: ThunkDispatch<State, ExtraThunkArg, BasicAction>,
  getState: () => State,
  extraArgument: ExtraThunkArg
) => ReturnType

export type ThunkActionDispatch<
  ActionCreator extends (...args: any[]) => ThunkAction<any, any, any, any>
  > = (
  ...args: Parameters<ActionCreator>
) => ReturnType<ReturnType<ActionCreator>>

export type ThunkMiddleware<
  State = any,
  BasicAction extends Action = AnyAction,
  ExtraThunkArg = undefined
  > = Middleware<
  ThunkDispatch<State, ExtraThunkArg, BasicAction>,
  State,
  ThunkDispatch<State, ExtraThunkArg, BasicAction>
  >
