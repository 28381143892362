import UserModel from '../../models/user';

export const PROCESSING = 'PROCESSING';
export const LOGGING_IN = 'LOGGING_IN';
export const LOGGING_OUT = 'LOGGING_OUT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';



// Action Types
export interface LoggingInType {
  type: typeof LOGGING_IN | typeof PROCESSING;
}

export interface LoggingOutType {
  type: typeof LOGGING_OUT;
}

export interface LoginSuccessType extends LoginResponseType {
  type: typeof LOGIN_SUCCESS;
}

export interface LoginFailureType {
  type: typeof LOGIN_FAILURE;
  err: string;
}

export interface LogoutSuccessType {
  type: typeof LOGOUT_SUCCESS;
}

export interface LogoutFailureType {
  type: typeof LOGOUT_FAILURE;
  err: string;
}

export type AuthActionTypes =
  | LoggingInType
  | LoggingOutType
  | LoginSuccessType
  | LoginFailureType
  | LogoutSuccessType
  | LogoutFailureType

export interface LoginResponseType {
  accessToken: string;
  storedUser: UserModel;
}
