import React from 'react';
import {IonGrid, IonRow, IonCol, IonImg } from "@ionic/react";
import {makeStyles} from '@material-ui/core/styles';
import { myStyle } from "./styles";
import {SuccessLogo} from "../successLogo";
import {ICON_DONE} from '../../../images'

interface Props {
  text: any;
  icon?: any;
  hasSuccessLogo?: boolean;
  successLogo?: any;
}

export const Heading: React.FC<Props> = ({ text, icon, hasSuccessLogo = true, successLogo = ICON_DONE }) => {

  const classes = makeStyles(() => ({
    ...myStyle, headingText: {
      ...myStyle.headingText,
      fontFamily: icon ? 'InfraMedium' : 'UrbanistMedium'
    }
  }))();

  return (
    <IonGrid className="ion-padding-top ion-padding-bottom pl-0">
        {hasSuccessLogo &&
          <IonRow className='ion-justify-content-center'>
            <IonCol className="flex-center" size="auto">
              <SuccessLogo logo={successLogo} />
            </IonCol>
          </IonRow>
        }
      {icon &&
        <IonRow>
          <IonCol className="pl-0 ion-padding-end" size="auto">
            <IonImg className={classes.headingIcon} src={icon}/>
          </IonCol>
          <IonCol className={'ion-text-start'}>
            <h2 className={classes.headingText}>{text}</h2>
          </IonCol>
        </IonRow>
      }
      {!icon &&
        <IonRow>
          <IonCol className={'ion-text-center'}>
            <h2 className={classes.headingText}>{text}</h2>
          </IonCol>
        </IonRow>
      }
    </IonGrid>
  )
};
