import React from "react";
import {style} from "./style";
import {makeStyles} from "@material-ui/core/styles";
import {QuestionMarkIcon} from "../icons";
import {IonText} from "@ionic/react";
import {colors} from "../../../business/constants/global";

export const WaiverStatusSection = ({ setIsOpen }) => {

  // @ts-ignore
  const classes = makeStyles(() => style(colors.cyan))();

  return (
    <IonText className="ion-text-center flex-center">
      <h2 className={classes.mediumTitle}>Waiver Coverage</h2>
      <QuestionMarkIcon onClick={() => setIsOpen(true)}/>
    </IonText>
  )
}
