import React from 'react';
import {IonRow, IonCol, IonImg} from '@ionic/react';
import './style.css';

export const Logo: React.FC = () => (
  <IonRow>
    <IonCol className="app-logo-box">
      <IonImg className="logo" src={`${process.env.REACT_APP_BASE}resident-app/c-logo/${window.location.hostname}`}/>
    </IonCol>
  </IonRow>
);
