import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from '@material-ui/core/styles';
import {syncLocalResident} from "../../business/redux/reducers/resident/actions"
import {UniversalButtonGroup} from "../../components/primitive/buttons"
import {HeaderSection} from "../../components/primitive/headerSection";
import {useHistory} from "react-router-dom";
import {QuestionMarkIcon} from "../../components/primitive/icons";
import {InformationModal} from "../../components/primitive/modals";
import {PerilsSection} from "../../components/primitive/perilsSection";
import {StoreModel} from "../../business/models/store";
import {IonCol, IonContent, IonFooter, IonGrid, IonImg, IonRow, IonText} from "@ionic/react";
import {Wrapper} from "../../../core/styles";
import {colors} from "../../business/constants/global";
import {startUrls} from "../../business/constants/startUrls";
import {selectCoverageStyle} from './styles';
import {
  CHANGE_INSURANCE_PROVIDER_ROUTE,
  CHOOSE_INSURANCE_ROUTE,
  COVERAGE_ROUTE, MOVE_IN_DATE_INFORMATION_ROUTE,
  PASSWORD_LESS_LOGIN_ROUTE,
} from "../../business/enums/routes"
import {ICON_DOCUMENT} from "../../images";

export const Coverage: React.FC = (): JSX.Element=> {

  let history = useHistory();

  const [isOpen, setIsOpen] = useState(false)

  let {state}: any = history.location

  // @ts-ignore
  const classes = makeStyles(() => selectCoverageStyle)();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch<any>(syncLocalResident())
  }, []);
  const { resident } = useSelector( (state: StoreModel) => state )

  const buttons = [
    {
      color: colors.cyan,
      value: "BUTTON.CONTINUE",
      onClick: () => {
        // @ts-ignore
        window._kmq.push(['record', 'Whether user clicked "Continue"'])
        history.push({
          pathname: PASSWORD_LESS_LOGIN_ROUTE.path,
          state: {
            ...state,
            prevUrl: COVERAGE_ROUTE.path
          }
        })
      }
    },
    {
      color: colors.white,
      value: "BUTTON.RENTERS_INSURANCE_POLICY",
      onClick: () => {
        // @ts-ignore
        window._kmq.push(['record', 'Whether user clicked "Upload renters insurance policy"'])
        history.push({
          pathname: state?.startUrl === startUrls.addressConfirmation
            ? CHANGE_INSURANCE_PROVIDER_ROUTE.path
            : CHOOSE_INSURANCE_ROUTE.path,
          state: {
            ...state,
            prevUrl: COVERAGE_ROUTE.path
          }
        })
      }
    },
  ];

  const backButtonClick = () => {
    switch (state?.startUrl) {
      case startUrls.addressConfirmation:
        return history.goBack();
      case startUrls.personalAccess:
        return history.push({
          pathname: MOVE_IN_DATE_INFORMATION_ROUTE.path,
          state: {
            prevUrl: COVERAGE_ROUTE.path,
            residentData: state.residentData
          }
        })
      default:
        return null;
    }
  }

  return (
    <>
      {
        resident && resident.success && resident?.resident?.uuid && (
          <IonContent>
            <Wrapper className="app-container ion-padding">

              <HeaderSection
                activeStep={2}
                hasBackButton={true}
                backClick={backButtonClick}
                previewSteps={state?.startUrl === startUrls.personalAccess}
              />

              <IonGrid className="ion-padding-top ion-padding-bottom pl-0">
                <IonRow className="ion-justify-content-center">
                  <IonCol className="ion-text-center ion-padding-top ion-padding-bottom">
                    <IonImg class="icon-document" src={ ICON_DOCUMENT }/>
                  </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center">
                  <IonCol size="10">
                    <h2 className={classes.headingWithoutIcon}>
                      Your unit is covered by</h2>
                    <h2 className={classes.selectCoverageInfo}>
                      <span>the Waiver</span>
                      <QuestionMarkIcon onClick={() => setIsOpen(true)} />
                    </h2>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonGrid className="ion-padding-bottom">
                <IonRow>
                  <IonCol className="ion-text-center ion-margin-bottom">
                    <IonText style={{fontSize: 18}}>
                      <p className="ion-no-margin">If you have renters insurance,</p>
                      <p className="ion-no-margin">upload it below prior to move-in.</p>
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonFooter>
                  <UniversalButtonGroup buttons={buttons} />
                </IonFooter>
              </IonGrid>
              <InformationModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                headerText={''}
                contextText={<PerilsSection/>}
              />
            </Wrapper>
          </IonContent>
        )
      }
    </>
  );
};
