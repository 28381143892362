import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {UniversalButton} from "../../../components/primitive/buttons";
import {addAndLinkProfile, updateResidentProfile} from "../../../business/redux/reducers/resident/actions";
import {makeStyles} from "@material-ui/core/styles";
import {getValidText} from "../../phoneVerification/functionHelpers";
import {validateEmail} from "../../../../core/business/helpers/input";
import {IonCheckbox, IonCol, IonFooter, IonGrid, IonIcon, IonInput, IonRow, IonText} from "@ionic/react";
import {colors} from "../../../business/constants/global";
import {contactFormStyle} from "../../phoneVerification/styles";
import {enqueueSnackbar} from "notistack";
import {HeaderTitle} from "../../../../core/components/primitives";
import {Clear} from '@mui/icons-material';
import {Heading} from "../../../components/primitive/heading";


export const PhoneCreateModal = ({userVerified, setUserVerified, receiveTextMessages, setReceiveTextMessages, resident, setIsOpenSuccessModal,setIsOpenPhoneModal, checkPhoneOrEmail, isOpenPhoneModal}) => {

  const useStyles = makeStyles(() => contactFormStyle);

  const classes = useStyles();

  const dispatch = useDispatch();

  const changeHandler = e => {

    const validationText = getValidText(userVerified.type, userVerified.value) || '* The user not exist'

    setUserVerified(prevState => ({
      ...prevState,
      sent: true,
      value: e.target.value,
      inValidText: validationText
    }))
  }

  const checkHandler = () => setReceiveTextMessages(s => !s);

  const clickHandler = () => {
    addAndLinkProfile({
      primary_auth_sub: resident?.resident?.auth0_sub,
      type: "phone_number",
      value: userVerified.value
    }).then(r => addReceiveTextMessage(resident?.resident))
  }

  const addReceiveTextMessage = (resident) => {
  // const addReceiveTextMessage = (resident, response) => {

    // if (response && response.status !== 'success')
    //   return enqueueSnackbar({variant: response.status, message: response.message})

    const data: {
      named_insured_first_name?: any;
      i_want_to_receive_text_messages?: any;
      login_by_phone_allowed?: any;
      phone?: any;
      resident_first_name?: any;
      resident_last_name?: any;
    } = {};

    data.named_insured_first_name = resident?.named_insured_first_name;
    data.i_want_to_receive_text_messages = receiveTextMessages ? 't' : 'f';
    data.login_by_phone_allowed = resident?.login_by_phone_allowed;
    data.phone = resident?.phone;
    data.resident_first_name = resident?.resident_first_name;
    data.resident_last_name = resident?.resident_last_name;

    // @ts-ignore
    dispatch(updateResidentProfile(resident?.uuid, data, () => {
      // setIsOpenPhoneModal(false)
      setIsOpenPhoneModal(false);
      enqueueSnackbar({
        variant: "success",
        message: "Phone profile added successfully"
      })
    }))
    return receiveTextMessages
  }

  const validated = (userVerified.type === 'email' && validateEmail(userVerified.value)) || (userVerified.type === 'phone_number'
    // && validatePhone(userVerified.value)
  )

  const errorText = (message: any) => (
    <IonText color="danger" className="ion-margin-top">
      <span role="alert">{message}</span>
    </IonText>
  )

  return (
    <>
      {!resident?.resident?.phone && isOpenPhoneModal && <>
        <IonGrid>
          <IonRow>
            <IonCol size="11">
              <strong>Please provide your cell phone</strong><br/>
              <strong>to get updates about policy status</strong>
            </IonCol>
            <IonCol size="1">
              <Clear style={{cursor: 'pointer'}} onClick={() => setIsOpenPhoneModal(false)} />
            </IonCol>
          </IonRow>
        </IonGrid>
        <div className={classes.root}>
          <div className={classes.inputGroup}>
            <IonInput
              mode="ios"
              type={'number'}
              placeholder={'Cell Phone'}
              value={userVerified.value}
              name={"phone_number"}
              onIonInput={changeHandler}
              className="ion-border ion-border-radius4"
            />
            {/*@ts-ignore*/}
            {/*{userVerified.sent && checkPhoneOrEmail?.status !== 'success' && errorText(checkPhoneOrEmail?.message || 'in valid phone number')}*/}
            {/*{checkPhoneOrEmail && checkPhoneOrEmail?.status !== "success" && errorText(checkPhoneOrEmail?.message || "")}*/}
            {/*{userVerified.type === 'phone_number' && <p className='ion-padding-start'>Mobile format: 5555551234</p>}*/}
          </div>

          <IonCheckbox
            justify="start"
            labelPlacement="end"
            onIonChange={checkHandler}
            checked={receiveTextMessages}
          >
            I agree to receive text messages
          </IonCheckbox>

        </div>


        <IonFooter>
          <UniversalButton
            // @ts-ignore
            disabled={userVerified.value.length !== 10}
            // disabled={!validated || checkPhoneOrEmail?.status !== 'success'}
            value={"BUTTON.SAVE"}
            onClick={() => addReceiveTextMessage(resident?.resident)}
            // onClick={clickHandler}
          />

          {/*{!resident?.resident?.phone_profile_exists &&*/}
          {/*  <UniversalButton*/}
          {/*    value={'BUTTON.SKIP'}*/}
          {/*    color={colors.white}*/}
          {/*    // @ts-ignore*/}
          {/*    onClick={() => dispatch(updateResidentProfile(*/}
          {/*      resident?.resident?.uuid,*/}
          {/*      // @ts-ignore*/}
          {/*      {*/}
          {/*        i_want_to_receive_text_messages: 'f',*/}
          {/*        login_by_phone_allowed: 'f'*/}
          {/*      },*/}
          {/*      () => {*/}
          {/*        setIsOpenPhoneModal(false)*/}
          {/*      }*/}
          {/*      // () => enqueueSnackbar({*/}
          {/*      //   variant: "success",*/}
          {/*      //   message: "Updated successfully"*/}
          {/*      // })*/}
          {/*    ))}*/}
          {/*  />}*/}

        </IonFooter>
      </>}
    </>
  )
}
