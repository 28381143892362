import React, {useEffect} from "react";
import {HeaderSection} from "../../components/primitive/headerSection";
import {makeStyles} from "@material-ui/core/styles";
import {style} from "../../components/primitive/coverageStatusSection/style";
import {useAuth0} from "@auth0/auth0-react";
import {UniversalButtonGroup} from "../../components/primitive/buttons";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getResidentByAuth} from "../../business/redux/reducers/resident/actions";
import {IonCol, IonContent, IonFooter, IonGrid, IonRow, IonText} from "@ionic/react";
import {ACCOUNT_SETTINGS_EDIT_ROUTE, USER_INFORMATION_ROUTE} from "../../business/enums/routes";
import {Wrapper} from "../../../core/styles";
import {colors} from "../../business/constants/global";
import {StoreModel} from "../../business/models/store";

export const AccountSettings: React.FC = (): JSX.Element => {

  const dispatch = useDispatch();

  const {user} = useAuth0();

  useEffect(() => {
    if (user?.sub) {
      // @ts-ignore
      dispatch(getResidentByAuth(user?.sub));
    }
  }, [user?.sub]);

  const {resident} = useSelector((state: StoreModel) => state)

  // @ts-ignore
  const classes = makeStyles(() => style(colors.cyan))();

  const {logout} = useAuth0();

  let history = useHistory();

  const buttons = [
    {
      color: colors.cyan,
      value: "BUTTON.BACK",
      onClick: () => history.push({
        pathname: USER_INFORMATION_ROUTE.path,
      })
    },
    {
      value: "BUTTON.EDIT",
      color: colors.white,
      onClick: () => history.push(ACCOUNT_SETTINGS_EDIT_ROUTE.path),
    },
  ]

  const appLogout = () => logout({returnTo: window.location.origin});

  return (
    <IonContent>
      <Wrapper className="app-container ion-padding">

        <IonGrid className="gray-bg-head ion-margin-top">
          <HeaderSection backClick={() => history.push({pathname: USER_INFORMATION_ROUTE.path})} hasBackButton={true} previewSteps={false}/>

          <IonRow>
            <IonCol className={'ion-text-center'}>
              <h2 className={classes.mediumTitle}>Account Settings</h2>
            </IonCol>
          </IonRow>

        </IonGrid>



        <IonRow style={{marginTop: 50}}>
          <IonText className="flex ion-float-left">
            <IonText style={{lineHeight: '24px', letterSpacing: 0.4, marginBottom: '10px', fontSize: 16}}>Resident Name</IonText>
          </IonText>
        </IonRow>
        <IonRow>
          <IonText className="flex ion-float-left">
            <h2 className={classes.mediumTitle}>{resident?.resident?.resident_first_name} {resident?.resident?.resident_last_name}</h2>
          </IonText>
        </IonRow>

        <IonRow style={{marginTop: 30}}>
          <IonText className="flex ion-float-left">
            <IonText style={{lineHeight: '24px', letterSpacing: 0.4, marginBottom: '10px', fontSize: 16}}>E-mail</IonText>
          </IonText>
        </IonRow>
        <IonRow>
          <IonText className="flex ion-float-left">
            <h2 className={classes.mediumTitle}>{resident?.resident?.email}</h2>
          </IonText>
        </IonRow>

        <IonRow style={{marginTop: 30}}>
          <IonText className="flex ion-float-left">
            <IonText style={{lineHeight: '24px', letterSpacing: 0.4, marginBottom: '10px', fontSize: 16}}>Cell</IonText>
          </IonText>
        </IonRow>
        <IonRow>
          <IonText className="flex ion-float-left">
            <h2 className={classes.mediumTitle}>{resident?.resident?.phone || '--'}</h2>
          </IonText>
        </IonRow>


        <IonFooter style={{marginTop: 200}}>
          <UniversalButtonGroup
            buttons={buttons}
          />
        </IonFooter>
      </Wrapper>
    </IonContent>
  );
};
