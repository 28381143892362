import { Action, AnyAction, Middleware } from 'redux'
import { Resident } from "../../../models/resident";

export const FETCH_RESIDENT_SUCCESS = 'FETCH_RESIDENT_SUCCESS';
export const FETCH_RESIDENT_FAILURE = 'FETCH_RESIDENT_FAILURE';
export const SAVE_RESIDENT_SUCCESS = 'SAVE_RESIDENT_SUCCESS';
export const SAVE_RESIDENT_FAILURE = 'SAVE_RESIDENT_FAILURE';
export const SELF_SIGN_UP_SUCCESS = 'SELF_SIGN_UP_SUCCESS';
export const SELF_SIGN_UP_FAILURE = 'SELF_SIGN_UP_FAILURE';
export const CLEAN_RESIDENT = 'CLEAN_RESIDENT';

// Action Types
export interface FetchResidentSuccessType {
  type: typeof FETCH_RESIDENT_SUCCESS;
  resident: Resident | null;
}

export interface FetchResidentFailureType {
  type: typeof FETCH_RESIDENT_FAILURE;
  err: string;
}

export interface SaveResidentSuccessType {
  type: typeof SAVE_RESIDENT_SUCCESS;
  resident: Resident;
}

export interface SaveResidentFailureType {
  type: typeof SAVE_RESIDENT_FAILURE;
  err: string;
}

export interface SelfSignUpSuccessType {
  type: typeof SELF_SIGN_UP_SUCCESS;
  resident: Resident;
}

export interface SelfSignUpFailureType {
  type: typeof SELF_SIGN_UP_FAILURE;
  err: string;
}

export interface CleanResidentType {
  type: typeof CLEAN_RESIDENT;
}

export type ResidentReducerType = Resident | null;

export type ResidentActionTypes =
  | FetchResidentSuccessType
  | FetchResidentFailureType
  | SaveResidentSuccessType
  | SaveResidentFailureType
  | SelfSignUpSuccessType
  | SelfSignUpFailureType
  | CleanResidentType;

export const RESIDENT_INIT_STATE = null




export interface ThunkDispatch<
  State,
  ExtraThunkArg,
  BasicAction extends Action
  > {
    <ReturnType>(
    thunkAction: ThunkAction<ReturnType, State, ExtraThunkArg, BasicAction>
  ): ReturnType
    <Action extends BasicAction>(action: Action): Action
    <ReturnType, Action extends BasicAction>(
    action: Action | ThunkAction<ReturnType, State, ExtraThunkArg, BasicAction>
  ): Action | ReturnType
}
export type ThunkAction<
  ReturnType,
  State,
  ExtraThunkArg,
  BasicAction extends Action
  > = (
  dispatch: ThunkDispatch<State, ExtraThunkArg, BasicAction>,
  getState: () => State,
  extraArgument: ExtraThunkArg
) => ReturnType

export type ThunkActionDispatch<
  ActionCreator extends (...args: any[]) => ThunkAction<any, any, any, any>
  > = (
  ...args: Parameters<ActionCreator>
) => ReturnType<ReturnType<ActionCreator>>

export type ThunkMiddleware<
  State = any,
  BasicAction extends Action = AnyAction,
  ExtraThunkArg = undefined
  > = Middleware<
  ThunkDispatch<State, ExtraThunkArg, BasicAction>,
  State,
  ThunkDispatch<State, ExtraThunkArg, BasicAction>
  >
