import {fetchApi} from "../../../core/business/services/api";

export const checkHasEmail = async (email: string) => {
  const url = `resident-app/check-email/${email}`
  const method = 'GET'
  const mockData = {
    success: true,
    exists: true
  }
  return await fetchApi({ url, method, mockData })
}
