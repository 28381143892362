import { Dispatch } from 'redux';
import { configs } from '../../../../../configs';
import { fetchApi, handleApiFail } from "../../../../../core/business/services/api";
import mockData  from '../../../mocks/policyDocument.json';

import { CLEAR_LOADING_BY_MIDDLE_TYPE, SET_LOADING_BY_MIDDLE_TYPE } from "../loadingByType/types";
import { FETCH_POLICY_DOCUMENT_SUCCESS, SAVE_POLICY_DOCUMENT_FAILURE } from "./types";
import {captureMessage} from "@sentry/react";

export const savePolicyDocument = () => {

}

export const uploadPolicy = (selectedInsuranceCompany, file, id, recordType, isMobile) => async (dispatch: Dispatch) => {

  if (!file) return null;

  if ( !["image/png", "image/jpeg", "application/pdf"].includes(file.type) ) {
    return handleApiFail(dispatch, SAVE_POLICY_DOCUMENT_FAILURE, {}, "ERROR.FILE_FORMAT", true);
  }

  dispatch({
    type: SET_LOADING_BY_MIDDLE_TYPE,
    loadingData: {
      loading: false,
      modalData: {
        message: ""
      }
    }
  });

  const formData = new FormData();
  formData.append("policyDocument", file);
  formData.append("insurance_company", selectedInsuranceCompany);
  formData.append("record_type", "secondary");
  formData.append("is_mobile", isMobile);

  if (!id) {
    dispatch({
      type: FETCH_POLICY_DOCUMENT_SUCCESS,
      policyDocument: null
    });
    return;
  }

  const url = `${configs.api.resident.general}/${id}/${configs.api.resident.policyDocument}`;

  const response = await fetchApi({
    url,
    method: "POST",
    formData,
    mockData
  });

  if (!response || !response?.success) {
    captureMessage("Something happened", "info");

    dispatch({
      type: CLEAR_LOADING_BY_MIDDLE_TYPE,
      loadingData: null
    });
    return handleApiFail(dispatch, SAVE_POLICY_DOCUMENT_FAILURE, response, "ERROR.SERVER", true);
  }

  dispatch({
    type: CLEAR_LOADING_BY_MIDDLE_TYPE,
    loadingData: null
  });

  dispatch({
    type: FETCH_POLICY_DOCUMENT_SUCCESS,
    policyDocument: response
  });
}
