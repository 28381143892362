import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {style} from "./style";
import {IonCol, IonGrid, IonImg, IonRow, IonText} from "@ionic/react";
import {ICON_DOCUMENT} from "../../../images";
import {colors} from "../../../business/constants/global";
import {useMediaQuery} from "@mui/material";
import {ENUMS} from "../../../../core/styles";
import {policyOccupancyStatusValues, policyStatusValues} from "../../../business/constants/resident";

export const StatusSection = ({children, color, resident = null }) => {

  const isMobile = useMediaQuery(`(max-width:${ENUMS.MOBILE_WIDTH})`);

  const className = style(color, isMobile);

  // @ts-ignore
  const isFutureApproved = resident?.status === policyStatusValues.approvedNotActiveYet

  const headerText = color === colors.cyan
    ? 'ACTIVE'
    : color === colors.yellow
      // @ts-ignore
      ? (isFutureApproved ? 'APPROVED - NOT ACTIVE YET' : 'PENDING REVIEW')
      : 'EXPIRING';

  // @ts-ignore
  const useStyles = makeStyles(() => ({
    ...className
  }));
  const classes = useStyles();

  return (
    <IonGrid className={classes.borderStyle}>
      <IonRow style={className.headerStyle} className="ion-align-items-center">
        <IonCol size="auto" className="ion-no-padding">
          <div className={classes.whiteRound}>
            <IonImg class="icon-document" src={ICON_DOCUMENT}/>
          </div>
        </IonCol>

        <IonCol size="auto">
          <IonText color="primary">
            <h2 className={classes.headerTitle}>{ headerText }</h2>
          </IonText>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="ion-padding">
          <IonText className="ion-text-center">

            {children}

          </IonText>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}
