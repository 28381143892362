import React from 'react';
import {Text} from "../../../../core/components/primitives";
import {makeStyles} from "@material-ui/core/styles";
import {IonRow, IonCol} from "@ionic/react";
import {stepStyle} from "./styles";

interface StepProps {
  activeStep: number;
  stepNames: string[];
}

export const StepsComponent: React.FC<StepProps> = ({activeStep, stepNames}) => {

  // @ts-ignore
  const useStyles = makeStyles(() => stepStyle);

  const classes = useStyles();
  const getClassName = idx => {
    if (activeStep > idx + 1) return classes.prevStep
    if (activeStep === idx + 1) return classes.activeStep
    if (activeStep < idx + 1) return classes.nextStep
  }
  return (
    <IonRow>
      <IonCol className="ion-padding-top">
        <ul className={classes.stepContent} >
          {
            stepNames.map((name, idx) => (
              <li key={idx} className={getClassName(idx)}>
                {idx !== stepNames.length - 1 && <span className={classes.stepLine}></span>}
                <Text k={`TEXT.${name}`}/>
              </li>
            ))
          }
        </ul>
      </IonCol>
    </IonRow>
  )
};
