import styled from 'styled-components';

import { SPACE } from '../../../styles';
import { FormControl, InputLabel } from '@mui/material';

export const FormControlWrapper = styled(FormControl)`
  .MuiTextField-root.left input {
    text-align: left;
  }

  .MuiInputBase-multiline textarea {
    height: initial !important;
  }
`;

export const Postfix = styled.div`
  position: absolute;
  padding: ${SPACE.MEDIUM};
  padding-right: ${SPACE.LARGE};
  color: var(--ion-color-medium);
  bottom: 0;
  right: 0;
`;

export const Img = styled.img`
  max-height: 18px;
  max-width: 28px;
`;

export const BSLabel = styled(InputLabel)`
  &&.bs-label {
    position: absolute;
    top: -8px;
    left: 0;
    font-size: 13px;
    font-weight: 500;
    transform: none;
  }
`;
