import React, {useEffect, useState} from "react";
import {HeaderSection} from "../../components/primitive/headerSection";
import {validateEmail, validatePhone} from "../../../core/business/helpers/input";
import {UniversalButton} from "../../components/primitive/buttons";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {IonCol, IonContent, IonFooter, IonGrid, IonImg, IonInput, IonLabel, IonRow, IonText} from "@ionic/react";
import {personalFormStyle} from "./styles";
import {colors} from "../../business/constants/global";
import {Wrapper} from "../../../core/styles";
import {
  COMMUNITY_INFORMATION_ROUTE,
  CONTACT_INFORMATION_ROUTE,
  ADD_YOUR_NAME_ROUTE
} from "../../business/enums/routes";
import {ICON_MAN} from "../../images";
import moment from "moment";

export const AddYourName = () => {

  useEffect(() => {
    // @ts-ignore
    window._kmq.push(['record', 'User enters first and last name'])
  }, []);

  let history: any = useHistory()

  const residentData = history.location.state?.residentData

  const [formData, setFormData] = useState({
    pr_id: residentData?.pr_id || '',
    u_id: residentData?.u_id || '',
    first_name: residentData?.first_name || '',
    last_name: residentData?.last_name || '',
    phone: residentData?.phone || '',
    sms_opt_in: residentData.sms_opt_in,
    email: residentData?.email || '',
    move_in_date: residentData?.move_in_date || moment().format('MM/DD/YYYY')
  })

  // @ts-ignore
  const useStyles = makeStyles(() => personalFormStyle)

  const requiredText = '* This field is required'

  const [sent, setSent] = useState(false)
  const errorText = (message) => (
    <IonText color="danger" className="ion-margin-top">
      <span role="alert">{message}</span>
    </IonText>
  )

  const validated =
    Boolean(formData.first_name) &&
    Boolean(formData.last_name) &&
    Boolean(formData.phone) &&
    validatePhone(formData.phone) &&
    Boolean(formData.email) &&
    validateEmail(formData.email);

  const classes = useStyles();

  const handleSubmit = () => {
    validated
      ? history.push({
        pathname: COMMUNITY_INFORMATION_ROUTE.path,
        state: {
          prevUrl: ADD_YOUR_NAME_ROUTE.path,
          residentData: formData
        }
      })
      : setSent(true);
  }

  const backIconClick = () => {
    history.push({
      pathname: CONTACT_INFORMATION_ROUTE.path,
      state: {
        prevUrl: ADD_YOUR_NAME_ROUTE.path,
        residentData: formData
      }
    })
  }

  return (
    <IonContent>
      <Wrapper className='app-container ion-padding'>

        <HeaderSection
          background={colors.grey}
          previewSteps={true}
          activeStep={1}
          hasBackButton={true}
          backClick={backIconClick}
        />

        <IonGrid className="ion-padding-top ion-padding-bottom pl-0">
          <IonRow className={classes.headingWithIcon}>
            <IonCol className="pl-0 ion-padding-end" size="auto">
              <IonImg className={classes.headingIcon} src={ICON_MAN}/>
            </IonCol>
            <IonCol size="9">
              <h2 className={classes.headingText}>Add your name</h2>
            </IonCol>
          </IonRow>
        </IonGrid>
        <div className={classes.root}>

          <div className={classes.inputGroup}>
            <IonLabel>First Name</IonLabel><br/>
            <IonInput
              mode="ios"
              value={formData.first_name}
              name={"firstName"}
              className="ion-border ion-border-radius4"
              onIonInput={(e: any) => setFormData({...formData, first_name: e.target.value})}

            />
            {sent && !Boolean(formData.first_name) && errorText(requiredText)}
          </div>

          <div className={classes.inputGroup}>
            <IonLabel>Last Name</IonLabel><br/>
            <IonInput
              mode="ios"
              value={formData.last_name}
              name={"lastName"}
              className="ion-border ion-bor der-radius4"
              onIonInput={(e: any) => setFormData({...formData, last_name: e.target.value})}
            />
            {sent && !Boolean(formData.last_name) && errorText(requiredText)}
          </div>
          <IonFooter>
            <UniversalButton
              value={"BUTTON.CONTINUE"}
              onClick={handleSubmit}
              disabled={!validated}
              isSubmit={true}
            />
          </IonFooter>
        </div>

      </Wrapper>
    </IonContent>
  )
}
