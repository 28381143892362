const buttonStyle = {
  fontWeight: 500,
  minHeight: 56,
  borderRadius: 34,
  fontSize: 16,
  textTransform: 'unset',
  whiteSpace: 'normal',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  '--box-shadow': 'none',
  '--background': 'transparent',
  '--border-radius': '50px',
  '--background-hover-opacity': 0,
  '--background-focused-opacity':0,
  '--background-activated-opacity':0,
  '--background-activated':0,
}

export const style = {
  cyanBtnStyles: {
    ...buttonStyle,
    background: '#43CED3',
    color: '#fff',
    border: '1px solid #43CED3',
    fontFamily: 'InfraMedium',
    letterSpacing: '0.2px',

  },
  whiteBtnStyles: {
    ...buttonStyle,
    background: '#fff',
    color: '#2D2D2D',
    border: '2px solid #43CED3',
    fontFamily: 'InfraMedium',
    letterSpacing: '0.2px',
    // &.lineHeightInherit{
    //   lineHeight: inherit,
    //   paddingTop: 5px,
    //   paddingBottom: 5px,
    // }
  },
}
