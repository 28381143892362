import React from 'react';
import {Text} from "../../../core/components/primitives";
import {LogOutButton} from "../primitive/buttons/logOutButton";
import {useAuth0} from "@auth0/auth0-react";
import {IonCol, IonContent, IonGrid, IonImg, IonRow, IonText} from "@ionic/react";
import {Wrapper} from "../../../core/styles";
import {ERROR_404} from "../../images";

interface Props {
  icon?: any;
  title?: string;
  errorMessage?: string;
}
export const ErrorComponent: React.FC<Props> = ({
                                          icon = ERROR_404,
                                          title = "ERROR.OOPS",
                                          errorMessage = "ERROR.PAGE_NOT_FOUND",
                                        }) => {
  const { isAuthenticated, logout } = useAuth0()

  return (
    <IonContent>
      <Wrapper className="app-container ion-padding page-not-found">
        <IonGrid>
          <IonRow className="ion-padding-top ion-text-center ion-margin-top">
            <IonCol>
              <IonImg className="error_404" src={icon} />
              <IonText><h1><Text k={title} /></h1></IonText>
              <IonText><p><Text k={errorMessage} /></p></IonText>
              {isAuthenticated && <LogOutButton logout={logout} />}
            </IonCol>
          </IonRow>
        </IonGrid>
      </Wrapper>
    </IonContent>
  );
};
