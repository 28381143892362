import styled from 'styled-components';

import { ENUMS, SPACE } from '../../../styles';

export const Wrapper = styled.div`
  ${p =>
    p.responsive
      ? ''
      : `
    height: 100%;
    overflow: auto;
  `}

  @media screen and (min-width: ${ENUMS.DESKTOP_MIN_WIDTH}) {
    .sort-item {
      display: none;
    }
  }

  .list-view {
    overflow: hidden;
  }

  .list-view > * {

  }

  .pagination {
    display: flex;
    justify-content: center;
  }
`;

export const NoResult = styled.div`
  margin: ${SPACE.LARGE};
`;
