import moment from "moment/moment";

export const diffDays = (startDate, endDate) =>
  Math.ceil(Math.abs(Date.parse(startDate) - Date.parse(endDate)) / (1000 * 60 * 60 * 24));

export const getDate = (date) => {

  if (moment(date).format('MM/DD/YYYY') === 'Invalid date') {
    return ''
    // return '-- -- --'
  }

  return moment(date).format('MM/DD/YYYY')
}
