import React from 'react';
import {UniversalButton} from '../../components/primitive/buttons';
import {ErrorComponent} from '../../components/errors';
import {HeaderSection} from "../../components/primitive/headerSection";
import {setChatIcon} from "../../business/helpers/util";
import {useHistory} from "react-router-dom";
import {IonContent, IonFooter, IonGrid, IonItemDivider} from '@ionic/react';
import {CONTACT_INFORMATION_ROUTE, EMAIL_VERIFICATION_ROUTE, PERSONAL_ACCESS_ROUTE,} from '../../business/enums/routes';
import {Wrapper} from '../../../core/styles';
import {colors} from '../../business/constants/global';

export const PersonalAccess: React.FC = () => {

  let history: any = useHistory();
  const residentData = history.location.state?.residentData

  const redirectUrl = () => {
    // @ts-ignore
    window._kmq.push(['record', 'Whether user clicked "Continue"'])
    history.push({
      pathname: CONTACT_INFORMATION_ROUTE.path,
      state: {
        prevUrl: PERSONAL_ACCESS_ROUTE.path,
        residentData
      }
    })
  }

  const emailVerificationRedirect = () => {
    // @ts-ignore
    window._kmq.push(['record', 'Whether user clicked "Login"'])
    history.push({
      pathname: EMAIL_VERIFICATION_ROUTE.path,
      state: {
        prevUrl: PERSONAL_ACCESS_ROUTE.path,
        residentData
      }
    })
  }

  const hostName = document.location.hostname

  if (!hostName) return <ErrorComponent errorMessage={'ERROR.NOT_PREV_URL'} />

  setChatIcon();

  return (
    <IonContent>
      <Wrapper className='app-container ion-padding'>

        <HeaderSection previewSteps={false} />

        <IonGrid className='start-page-header'>
          <p className='ion-text-center'>A tool to manage your renters insurance coverage</p>
        </IonGrid>
        <IonGrid className='start-page-top-info'>
          <p className='ion-text-center ion-no-margin'>Start here by clicking “Continue”.</p>
          <p className='ion-text-center ion-no-margin'>If you are a returning user, click “Log in”</p>
        </IonGrid>
        <IonFooter>
        <UniversalButton
          value={'BUTTON.CONTINUE'}
          onClick={redirectUrl}
          disabled={false}
        />
          <IonItemDivider className='divider-or'>
            <div className='ion-text-center divider-or-cont'> or</div>
          </IonItemDivider>

          <UniversalButton
            value={'BUTTON.LOGIN'}
            color={colors.white}
            onClick={emailVerificationRedirect}
            disabled={false}
          />

          </IonFooter>
        <IonGrid className='start-page-bottom-info'>
          <p className='ion-text-center'>*This tool is required by your property to ensure renters insurance coverage</p>
        </IonGrid>
      </Wrapper>
    </IonContent>
  );
};
