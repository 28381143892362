export const passwordConfirmationStyle = {
  headingWithIcon: {
    alignItems: 'center',
  },
  headingIcon: {
    maxWidth: 44,
  },
  headingText: {
    margin: 0,
    color: '#2D2D2D',
    letterSpacing: '0.2px',
    fontSize: 16
  }
}
