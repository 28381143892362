import React from "react";
import {QuestionMarkIcon} from "../icons";
import {IonCol, IonRow} from "@ionic/react";
import {colors} from "../../../business/constants/global";

export const WhatIsWaiverCoverageText = ({setIsOpen}) => (
  <IonRow>
    <IonCol className="ion-text-center flex-center">
      What is &nbsp;
      <span style={{color: colors.cyan}}>Waiver coverage</span>&nbsp;
      <QuestionMarkIcon onClick={() => setIsOpen(true)}/>
    </IonCol>
  </IonRow>
)
