import React, {useEffect, useState} from "react";
import {IonCol, IonRow, IonButton} from "@ionic/react";
import {useHistory} from "react-router-dom";
import {Text} from "../../../../../core/components/primitives";
import {makeStyles} from "@material-ui/core/styles";
import {style} from "./style";
import {colors} from "../../../../business/constants/global";

interface Props {
  disabled?: boolean
  value?: string
  onClick?: any
  cssStyle?: any
  param?: any
  event?: any
  getRootProps?: any
  getInputProps?: any
  isHide?: boolean
  color?: string
  isSubmit?: boolean
}

export const UniversalButton: React.FC<Props> = ({
                                                   disabled = false,
                                                   value = "BUTTON.OK",
                                                   onClick = () => {
                                                   },
                                                   cssStyle = {},
                                                   param = null,
                                                   event = null,
                                                   getRootProps = null,
                                                   getInputProps = null,
                                                   isHide = false,
                                                   color = colors.cyan,
                                                   isSubmit = false
                                                 }) => {
  const [code, setCode] = useState('');

  const handleSetCode = e => setCode(e?.code)

  useEffect(() => {
    document.addEventListener('keydown', handleSetCode)
    return document.removeEventListener('keyup', handleSetCode)
  }, [])

  // @ts-ignore
  const useStyles = makeStyles(() => style);

  const classes = useStyles();

  const newClassName = color === colors.cyan
    ? classes.cyanBtnStyles
    : classes.whiteBtnStyles

  const clickHandler = () => {
    if (event) {
      // @ts-ignore
      window.dataLayer.push(event)
    }
    onClick()
  }

  if (code === 'Enter' && !disabled && isSubmit) {
    onClick()
  }

  return (
    <IonRow>
      {!isHide &&
        <IonCol {...getRootProps} className="ion-text-center">
          <IonButton
            {...getInputProps}
            style={cssStyle}
            className={newClassName}
            onClick={clickHandler}
            disabled={disabled}
          >
            <Text k={value}/>
          </IonButton>
        </IonCol>
      }
    </IonRow>
  );
};
