import {
  PolicyDocumentActionTypes,
  PolicyDocumentReducerType,
  POLICY_DOCUMENT_INIT_STATE,
  FETCH_POLICY_DOCUMENT_SUCCESS,
  FETCH_POLICY_DOCUMENT_FAILURE,
  SAVE_POLICY_DOCUMENT_SUCCESS,
  CLEAN_POLICY_DOCUMENT,
} from './types';

export default function policyDocumentReducer(
  state: PolicyDocumentReducerType = POLICY_DOCUMENT_INIT_STATE,
  action: PolicyDocumentActionTypes,
): PolicyDocumentReducerType {
  switch (action.type) {
    case FETCH_POLICY_DOCUMENT_SUCCESS:
      return action.policyDocument
    case FETCH_POLICY_DOCUMENT_FAILURE:
      return POLICY_DOCUMENT_INIT_STATE
    case SAVE_POLICY_DOCUMENT_SUCCESS:
      return state
    case CLEAN_POLICY_DOCUMENT:
      return POLICY_DOCUMENT_INIT_STATE;
    default:
      return state;
  }
}
