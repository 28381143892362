export const colors = {
  cyan: '#43CED3',
  white: '#fff',
  red: '#FF796C',
  yellow: '#EE9F27',
  grey: 'rgba(45, 45, 45, 0.03)',
}

export const buttonTypes = {
  click: 'click',
  link: 'link',
}
