export const myStyle = {
  headingIcon: {
    maxWidth: 44,
  },
  headingText: {
    margin: 0,
    color: '#2D2D2D',
    letterSpacing: '0.2px',
    fontSize: 22
  }
}
