import React from "react";
import {Heading} from "../heading";
import {Introduction} from "../util";
import {IonCol, IonGrid, IonRow, IonText, IonToolbar} from "@ionic/react";
import {
  ICON_FIRE,
  ICON_SMOKE,
  ICON_SEWER_BACKUP,
  ICON_WATER_DISCHARGE,
  ICON_EXPLOSION,
} from "../../../images";
import Vimeo from '@u-wave/react-vimeo';

export const PerilsSection: React.FC = (): JSX.Element => (
  <IonGrid>
    <Heading text={'What is Waiver coverage?'} hasSuccessLogo={false}/>
    <IonRow>
      <IonCol className="ion-text-center">
        <IonToolbar>
          <Vimeo
            video="494215343"
            controls={true}
            autoplay={false}
            playsInline={true}
            width='100%'
            responsive={true}
            className="video-video-container"
          />
        </IonToolbar>
      </IonCol>
    </IonRow>
    <IonRow>
      <IonCol className="ion-padding-top ion-padding-bottom">
        <IonText color="dark">
          <p>The Waiver covers accidental damage you cause to the property due to the
            <strong> 5 covered perils</strong> (up to $300,000 per incident):
          </p>
        </IonText>
      </IonCol>
    </IonRow>
    <IonGrid fixed={true}>
      <IonRow>
        <IonCol size="4" className="ion-padding-top ion-padding-bottom">
          <Introduction icon={ICON_FIRE} text={"Fire"}/>
        </IonCol>
        <IonCol size="4" className="ion-padding-top ion-padding-bottom">
          <Introduction icon={ICON_EXPLOSION} text={"EXPLOSION"}/>
        </IonCol>
        <IonCol size="4" className="ion-padding-top ion-padding-bottom">
          <Introduction icon={ICON_SMOKE} text={"Smoke"}/>
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-center">
        <IonCol size="4" className="ion-padding-top ion-padding-bottom">
          <Introduction icon={ICON_WATER_DISCHARGE} text={"WATER DISCHARGE"}/>
        </IonCol>
        <IonCol size="4" className="ion-padding-top ion-padding-bottom">
          <Introduction icon={ICON_SEWER_BACKUP} text={"SEWER BACKUP"}/>
        </IonCol>
      </IonRow>
    </IonGrid>
  </IonGrid>
);
