import _ from 'lodash';
import React from 'react';
import { IonItem, IonLabel, IonIcon, IonSelect } from '@ionic/react';
import { FastField, Field, ErrorMessage } from 'formik';

import intl from '../../../intl';
import { Styles } from '../../../styles';
import { input } from '../../utils';
import { configs } from '../../../../configs';

interface SelectInputProps {
  icon?: string;
  label?: string;
  labelText?: string;
  name: string;
  formik?: any;
  disabled?: boolean;
  readonly?: boolean;
  placeholder?: string;
  position?: string;
  multiSelect?: boolean;
  options: React.ReactNode | null;
  defaultValue?: string | number | null;
  handleChange?: (e) => void;
  disableFastField?: boolean;
}

const defaultProps: SelectInputProps = {
  icon: '',
  label: '',
  labelText: '',
  name: '',
  formik: {},
  disabled: false,
  readonly: false,
  placeholder: '',
  position: '',
  multiSelect: false,
  options: null,
  defaultValue: null,
  handleChange: () => {},
  disableFastField: false,
};

const getPosition = (position) => {
  return position || configs.defaultLabelPosition;
};

const SelectInput: React.FC<SelectInputProps> = ({
  icon,
  label,
  labelText,
  name,
  formik,
  disabled,
  readonly,
  placeholder,
  position,
  multiSelect,
  options,
  defaultValue,
  handleChange,
  disableFastField,
  ...props
}) => {
  const onChange = (e) => {
    if (formik.handleChange) formik.handleChange(e);
    if (handleChange) handleChange(e);
  };

  const ionOptions = input.generateSelectOptions(options);

  const FormField = disableFastField ? Field : FastField;
  const ErrMsg: any = ErrorMessage;

  return (
    <>
      <FormField name={name}>
        {({ form }) => (
          <>
            <IonItem className={readonly ? 'readonly' : ''}>
              {icon !== '' && <IonIcon slot="start" icon={icon} />}
              {(label !== '' || labelText !== '') && (
                <IonLabel position={getPosition(position)}>{labelText || intl(label)}</IonLabel>
              )}
              <ErrMsg name={name}>
                {(e) => <Styles.InputError>{intl(`INPUT.ERROR.${e}`)}</Styles.InputError>}
              </ErrMsg>
              <IonSelect
                {...props}
                name={name}
                disabled={disabled}
                placeholder={intl(placeholder) || undefined}
                onIonChange={onChange}
                value={_.get(form.values, name)}>
                {ionOptions}
              </IonSelect>
            </IonItem>
          </>
        )}
      </FormField>
    </>
  );
};

SelectInput.defaultProps = defaultProps;

export default SelectInput;
