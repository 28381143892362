import styled from 'styled-components';
import { InputLabel, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

export const style = {
  fileHeader: {
    marginTop: 0,
    '& ion-img' : {
      right: 0,
      bottom: '-1.5px',
      cursor: 'pointer',
      display: 'inline-block',
      position: 'absolute',
    },
    '& span' :{
      position: 'relative',
      display: 'inline-block',
      paddingRight: 30,
    }
  }
}

export const AutoCompleteWrapper = styled(Autocomplete)`
  .MuiAutocomplete-inputRoot {
    padding-right: 16px !important;
  }
`;

export const BSLabel = styled(InputLabel)`
  &&.MuiFormLabel-root {
    position: absolute;
    top: -8px;
    left: 0;
    font-size: 13px;
    font-weight: 500;
    transform: none;
  }
`;

export const TextFieldWrapper = styled(TextField)`
  &&.small {
    .MuiInputBase-root {
      padding: 0;
    }
    input {
      padding: 10.5px 14px !important;
    }
  }
`;
