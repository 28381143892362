import React, {useEffect} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {ErrorComponent} from "../components/errors";
import {Spinner} from "../components/primitive/spinner";
import {PrivateRoute} from "./privateRoute";
import {PublicRoute} from "./publicRoute";
import {Redirect, Route} from "react-router-dom";
import {IonRouterOutlet} from "@ionic/react";
import {ROUTES} from "../business/enums";
import {MY_COVERAGE_ROUTE, PERSONAL_ACCESS_ROUTE} from "../business/enums/routes";
import {useSelector} from "react-redux";
import {StoreModel} from "../business/models/store";

export const AppOutlet: React.FC = () => {

  const { isLoading, error, isAuthenticated, user } = useAuth0();
  const { loadingByType } = useSelector((state: StoreModel) => state);

  useEffect(() => {
    if (user) {
      // @ts-ignore
      window._kmq.push(['identify', user.email])
    }
  }, [user])

  if (!loadingByType && !isLoading && error) return <ErrorComponent />;
  if (isLoading) return <Spinner />;

  return (
    <IonRouterOutlet id="main" animated={false}>

      {/*{isAuthenticated &&*/}
        <Route exact path="/">
          <Redirect to={isAuthenticated ? MY_COVERAGE_ROUTE.path : PERSONAL_ACCESS_ROUTE.path} />
        </Route>
      {/*}*/}

      {ROUTES.PUBLIC_ROUTES.map(routeModel => (
        <PublicRoute
          key={routeModel.path}
          path={routeModel.path}
          param={routeModel.param || ""}
          component={routeModel.component}
        />
      ))}

      {ROUTES.PRIVATE_ROUTES.map(routeModel => (
        <PrivateRoute
          key={routeModel.path}
          path={routeModel.path}
          param={routeModel.param || ""}
          component={routeModel.component}
        />
      ))}

      {!isLoading && !loadingByType.loading && error && <Route path='/' component={ErrorComponent} />}

    </IonRouterOutlet>
  );
};
