import React from "react";
import { Route } from "react-router-dom";
import {setChatIcon} from "../business/helpers/util";

export const PublicRoute = ({ param, exact = true, path, component }) => {
  setChatIcon();
  return <Route path={`${path}${ param ? `/${param}` : ''}`} component={component} />
};


