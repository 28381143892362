import LocalStorageModel from '../../models/storage';

export const LOAD_STORAGE = 'LOAD_STORAGE';
export const SET_ITEM = 'SET_ITEM';
export const SET_ITEMS = 'SET_ITEMS';

export interface LoadStorageType {
  type: typeof LOAD_STORAGE;
  payload: LocalStorageModel;
}

export interface SetItemType {
  type: typeof SET_ITEM;
  payload: {
    key: string;
  };
}

export interface SetItemsType {
  type: typeof SET_ITEMS;
  payload: LocalStorageModel;
}

export type LocalStorageActionTypes = SetItemsType | LoadStorageType | SetItemType;

// Reducer Model
export const LOCAL_STORAGE_INIT_STATE: LocalStorageModel = {
  userId: 0,
  deviceId: '',
  accessToken: '',
  refreshToken: '',
  expTimestp: 0,
};
