import React from "react";
import {useAuth0} from "@auth0/auth0-react";
import {setChatIcon} from "../business/helpers/util";
import {Route, RouteProps} from "react-router-dom";
import {auth0Configs} from "../../configs";

export interface PrivateRouteProps extends RouteProps {
  key: any;
  path: any;
  param?: any;
  component: any;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
                                                     path,
                                                     param = "",
                                                     component
                                                   }) => {
  setChatIcon();
  const {audience} = auth0Configs
  const {loginWithRedirect, isAuthenticated} = useAuth0()

  return (
    <Route
      path={`${path}${param ? `/${param}` : ""}`}
      component={isAuthenticated ? component : () => loginWithRedirect({audience})}
    />
  );
};
