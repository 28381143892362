import React from "react";
import {HeaderSection} from "../../components/primitive/headerSection";
import {Heading} from "../../components/primitive/heading";
import {UniversalButton} from "../../components/primitive/buttons";
import {makeStyles} from "@material-ui/core/styles";
import {IonContent, IonFooter, IonImg} from "@ionic/react";
import {Wrapper} from "../../../core/styles";
import {styles} from "./style";
import {DECLARATION_EXAMPLE} from "../../images";

interface FileInfoProps {
  closeFileInfo?: any;
}

export const FileInfo: React.FC<FileInfoProps> = ({closeFileInfo}) => {
  const classes = makeStyles(() => styles)()
  return (
    <IonContent>
      <Wrapper className='app-container ion-padding'>

        <HeaderSection hasBackButton={true} previewSteps={false} backClick={closeFileInfo}/>

        <Heading hasSuccessLogo={false} text={'What is a declaration page?'}/>

        <p>
          Your renters insurance declarations page (aka cerfificate of insurance or Acord page) is provided by your
          insurance company as proof of coverage. It provides a summary of the coverage provided in your renters
          insurance policy and is usually the first page of your policy.
        </p>

        <IonImg src={DECLARATION_EXAMPLE}/>

        <p className={classes.footerText}>
          Important!<br/>
          Your declaration page might look differently from the example.
        </p>

        <IonFooter>
          <UniversalButton
            value={'BUTTON.CONTINUE'}
            onClick={closeFileInfo}
            disabled={false}
          />
        </IonFooter>
      </Wrapper>
    </IonContent>
  )
}
