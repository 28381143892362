export const policyOccupancyStatusValues = {
  current: "current",
  future: "future",
}

export const policyPhaseValues = {
  newMoveIn: "New Move in",
  futureMoveIn: "Future Move In",
  standardResident: "Standard Resident",
  futureResident: "Future Resident",
  expiration: "Expiration",
  changeOfCoverage: "Change of coverage",
}

export const policyStatusValues = {
  chat: "Chat",
  approved: "Approved",
  archived: "Archived",
  ho4Abandoned: "HO4 Abandoned",
  approvedNotActiveYet: "Approved - Not Active Yet",
  notified: "Notified",
  pdlwAssigned: "PDLW Assigned",
  newHo4InProgress: "New HO4 In Progress",
  inProgress: "In Progress",
  newHo4ReadyForReview: "New HO4 ready for review",
  noInvitationSent: "No Invitation Sent",
  nA: "N/A",
}

export const policySubPhaseValues = {
  accountEstablished: "Account established",
  inviteSent: "Invite sent",
  inProgress: "In Progress",
  ho4_established: "HO4 Established",
  pdlw_established: "PDLW Established",
  expired: "Expired",
  expiring_within_5_days: "Expiring Within 5 Days",
  expiring_within_15_days: "Expiring Within 15 Days",
  expiring_within_30_days: "Expiring Within 30 Days",
  from_pdlw_to_ho4: "PDLW - HO4",
  from_ho4_to_ho4: "HO4 - HO4",
  from_ho4_to_pdlw: "HO4 - PDLW",
}

export const policyTypeValues = {
  HO4: "HO4",
  PDLW: "PDLW",
}

export const policyRecordTypeTypeValues = {
  secondary: "secondary",
  primary: "primary",
}

export const residentTypeValues = {
  personInformation: "Personal Information",
  residentInformation: "residentInformation",
}

export const text = {
  personInformation: "Personal Information",
}
