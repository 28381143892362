import {Resident} from "../../../business/models/resident";

export const isExpired = (resident: Resident | undefined) => {

  const daysUtilExp = Boolean(resident)
    ? Number(resident?.days_until_expiration)
    : 0;

    return daysUtilExp > 0 && daysUtilExp < 10
}
