import {colors} from "../../business/constants/global";

interface HeadingWithoutIcon {
  margin: number;
  color: string,
  letterSpacing: string;
  fontFamily: string;
  textAlign: string;
  fontSize: number;
}

interface SelectCoverageInfo {
  display: string;
  justifyContent: string;
  fontSize: number;
  marginTop: number;
}

export interface SelectCoverageStyle {
  headingWithoutIcon: HeadingWithoutIcon;
  selectCoverageInfo: SelectCoverageInfo;
}

export const selectCoverageStyle = {
  headingWithoutIcon: {
    margin: 0,
    color: '#2D2D2D',
    letterSpacing: '0.2px',
    fontFamily: 'InfraMedium',
    textAlign: 'center',
    fontSize: 22,
  },
  selectCoverageInfo: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 22,
    marginTop: 0,
    color: colors.cyan,
    '& a' :{
      display: 'flex',
      alignItems: 'center'
    }
  }
}
