export const setChatIcon = () => {
  // @ts-ignore
  window.$zoho = window.$zoho || {};
  // @ts-ignore
  window.$zoho.salesiq = window.$zoho.salesiq || {
    widgetcode: "c90c5d021eae15d5682f53268240cf72e21120a8bb9daaf82302c1907b16427d1a2010ab7b6727677d37b27582c0e9c4",
    values: {},
    ready: function () {
      // @ts-ignore
      window.$zoho.salesiq.floatwindow.visible("hide")
      // window.$zoho.salesiq.floatwindow.visible( isOpen ? "show" : "hide")
    }
  };
  const d = document;
  let s;
  s = d.createElement("script");
  s.type = "text/javascript";
  s.id = "zsiqscript";
  s.defer = false;
  s.src = "https://salesiq.zoho.com/widget";
  let t;
  t = d.getElementsByTagName("script")[0];
  t.parentNode.insertBefore(s, t);

  // @ts-ignore
  window.$zoho.salesiq.ready=function(embedinfo)
  {
    // @ts-ignore
    window.$zoho.salesiq.floatwindow.onlinetitle('We\'re online')
    // @ts-ignore
    window.$zoho.salesiq.floatwindow.offlinetitle('We\'re offline')
    // @ts-ignore
    window.$zoho.salesiq.chat.messagehint("We are here to help from 8am to 5pm Mountain Time.");
  }
}
