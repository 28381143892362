import _ from 'lodash';
import React from 'react';
import { format as dateFormat } from 'date-fns';
import { IonItem, IonLabel, IonIcon, IonDatetime } from '@ionic/react';
import { FastField, Field, ErrorMessage } from 'formik';

import intl from '../../../intl';
import { Styles } from '../../../styles';
import { configs } from '../../../../configs';

interface DateInputProps {
  icon?: string;
  label?: string;
  labelText?: string;
  name: string;
  formik?: any;
  readonly?: boolean;
  disabled?: boolean;
  placeholder?: string;
  position?: string;
  minDate?: string;
  maxDate?: string;
  format?: string;
  defaultValue?: string;
  handleChange?: (e) => void;
  disableFastField?: boolean;
}

const defaultProps: DateInputProps = {
  icon: '',
  label: '',
  labelText: '',
  name: '',
  formik: {},
  readonly: false,
  disabled: false,
  placeholder: '',
  position: '',
  minDate: '1900',
  maxDate: '2100',
  // maxDate: dateFormat(new Date(), 'yyyy-MM-dd'),
  format: 'MM/dd/yyyy',
  defaultValue: '',
  disableFastField: false,
  handleChange: (e) => {},
};

const getPosition = (position) => {
  return position || configs.defaultLabelPosition;
};

const DateInput: React.FC<DateInputProps> = ({
  icon,
  label,
  labelText,
  name,
  formik,
  readonly,
  disabled,
  placeholder,
  position,
  minDate,
  maxDate,
  format,
  defaultValue,
  disableFastField,
  handleChange,
  ...props
}) => {
  const onChange = (e) => {
    if (formik.handleChange) formik.handleChange(e);
    if (handleChange) handleChange(e);
  };

  const FormField = disableFastField ? Field : FastField;
  const ErrMsg: any = ErrorMessage;

  return (
    <>
      <FormField name={name}>
        {({ form }) => (
          <>
            <IonItem>
              {icon !== '' && <IonIcon slot="start" icon={icon} />}
              {(label !== '' || labelText !== '') && (
                <IonLabel position={getPosition(position)}>{labelText || intl(label)}</IonLabel>
              )}
              <ErrMsg name={name}>
                {(e) => <Styles.InputError>{intl(`INPUT.ERROR.${e}`)}</Styles.InputError>}
              </ErrMsg>
              <IonDatetime
                {...props}
                readonly={readonly}
                disabled={disabled}
                name={name}
                min={minDate}
                max={maxDate}
                placeholder={intl(placeholder) || undefined}
                onIonChange={onChange}
                value={_.get(form.values, name)}></IonDatetime>
            </IonItem>
          </>
        )}
      </FormField>
    </>
  );
};

DateInput.defaultProps = defaultProps;

export default DateInput;
