import _ from 'lodash';
import React from 'react';
import { Field, FastField } from 'formik';

import intl from '../../../intl';
import { Button, ButtonGroup, ButtonProps } from '@mui/material';
import { Wrapper } from './styles';

interface ButtonSelectProps {
  icon?: string;
  label?: string;
  labelText?: string;
  name: string;
  disabled?: boolean;
  readonly?: boolean;
  position?: string;
  color?: ButtonProps['color'];
  formik?: any;
  options: Array<{ value: string; label: string }>;
  defaultValue?: string | number | null;
  handleChange?: (e, value) => void;
  disableFastField?: boolean;
}

const defaultProps: ButtonSelectProps = {
  icon: '',
  label: '',
  labelText: '',
  name: '',
  color: 'primary',
  disabled: false,
  readonly: false,
  position: 'floating',
  formik: {},
  defaultValue: null,
  options: [],
  handleChange: () => {},
  disableFastField: false,
};

const ButtonSelect: React.FC<ButtonSelectProps> = ({
  icon,
  label,
  labelText,
  name,
  color,
  disabled,
  readonly,
  position,
  formik,
  options,
  defaultValue,
  handleChange,
  disableFastField,
}) => {
  const onSelect = (value) => {
    if (formik.setFieldValue) formik.setFieldValue(name, value);
    if (handleChange) handleChange(null, value);
  };

  const FormField = disableFastField ? Field : FastField;

  return (
    <>
      <FormField name={name}>
        {({ form }) => {
          return (
            <Wrapper>
              <label className="MuiFormLabel-root">{labelText || intl(label)}: </label>
              <ButtonGroup color={color}>
                {options.map((button, index) => {
                  const variant = _.get(form.values, name) === button.value ? 'contained' : 'outlined';
                  return (
                    <Button key={index} variant={variant} onClick={() => onSelect(button.value)}>
                      {intl('INPUT.OPTION.' + button.label)}
                    </Button>
                  );
                })}
              </ButtonGroup>
              <input type="hidden" name={name} value={_.get(form.values, name)} />
            </Wrapper>
          );
        }}
      </FormField>
    </>
  );
};

ButtonSelect.defaultProps = defaultProps;

export default ButtonSelect;
