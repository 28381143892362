import {
  CLEAN_RESIDENT,
  FETCH_RESIDENT_FAILURE,
  FETCH_RESIDENT_SUCCESS,
  RESIDENT_INIT_STATE,
  SAVE_RESIDENT_SUCCESS,
  SELF_SIGN_UP_FAILURE,
  SELF_SIGN_UP_SUCCESS,
  ResidentActionTypes,
  ResidentReducerType,
} from './types';

export default function residentReducer(
  state: ResidentReducerType = RESIDENT_INIT_STATE,
  action: ResidentActionTypes,
): ResidentReducerType {
  switch (action.type) {
    case FETCH_RESIDENT_SUCCESS:
      return action.resident
    case FETCH_RESIDENT_FAILURE:
      return RESIDENT_INIT_STATE
    case SELF_SIGN_UP_SUCCESS:
      return action.resident
    case SELF_SIGN_UP_FAILURE:
      return RESIDENT_INIT_STATE
    case SAVE_RESIDENT_SUCCESS:
      return state
    case CLEAN_RESIDENT:
      return RESIDENT_INIT_STATE;
    default:
      return state;
  }
}
