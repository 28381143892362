import { Action, AnyAction, Middleware } from 'redux'
import { PropertyNameModel } from "../../../models/property";

export const FETCH_PROPERTY_NAMES_SUCCESS = 'FETCH_PROPERTY_SUCCESS';
export const FETCH_PROPERTY_NAMES_FAILURE = 'FETCH_PROPERTY_FAILURE';

// Action Types
export interface FetchPropertyNamesSuccessType {
  type: typeof FETCH_PROPERTY_NAMES_SUCCESS;
  propertyNames: { success: boolean, property_names: Array<PropertyNameModel> } | null;
}

export interface FetchPropertyNamesFailureType {
  type: typeof FETCH_PROPERTY_NAMES_FAILURE;
  err: string;
  propertyNames: { success: boolean, property_names: [] };
}

export type PropertyNamesReducerType = { success: boolean, property_names: Array<PropertyNameModel> } | null;

export type PropertyNamesActionTypes = FetchPropertyNamesSuccessType | FetchPropertyNamesFailureType;

export const PROPERTY_NAMES_INIT_STATE = null




export interface ThunkDispatch<
  State,
  ExtraThunkArg,
  BasicAction extends Action
  > {
    <ReturnType>(
    thunkAction: ThunkAction<ReturnType, State, ExtraThunkArg, BasicAction>
  ): ReturnType
    <Action extends BasicAction>(action: Action): Action
    <ReturnType, Action extends BasicAction>(
    action: Action | ThunkAction<ReturnType, State, ExtraThunkArg, BasicAction>
  ): Action | ReturnType
}
export type ThunkAction<
  ReturnType,
  State,
  ExtraThunkArg,
  BasicAction extends Action
  > = (
  dispatch: ThunkDispatch<State, ExtraThunkArg, BasicAction>,
  getState: () => State,
  extraArgument: ExtraThunkArg
) => ReturnType

export type ThunkActionDispatch<
  ActionCreator extends (...args: any[]) => ThunkAction<any, any, any, any>
  > = (
  ...args: Parameters<ActionCreator>
) => ReturnType<ReturnType<ActionCreator>>

export type ThunkMiddleware<
  State = any,
  BasicAction extends Action = AnyAction,
  ExtraThunkArg = undefined
  > = Middleware<
  ThunkDispatch<State, ExtraThunkArg, BasicAction>,
  State,
  ThunkDispatch<State, ExtraThunkArg, BasicAction>
  >
