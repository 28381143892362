import React from "react";
import {useAuth0} from "@auth0/auth0-react";
import {Logo} from "../logo";
import {StepsComponent} from "../stepsComponent";
import {IonGrid, IonImg} from "@ionic/react";
import {colors} from "../../../business/constants/global";
import {ICON_BACK} from "../../../images";
import {MenuDropdownButton} from "../buttons";
import {useLocation} from "react-router-dom";
import {USER_INFORMATION_ROUTE} from "../../../business/enums/routes";

interface Props {
  activeStep?: number;
  stepNames?: Array<string>|null;
  backClick?: Function;
  hasBackButton?: boolean;
  previewSteps?: boolean;
  background?: string;
}

const defaultStepNames = ['PERSONAL_INFORMATION', 'POLICY_INFORMATION', 'LOG_IN'];

export const HeaderSection: React.FC<Props> = ({
                                                 activeStep = 1,
                                                 stepNames = defaultStepNames,
                                                 hasBackButton = false,
                                                 backClick = () => window.history.back(),
                                                 previewSteps = true,
                                                 background = colors.white
}) => {

  const {isAuthenticated} = useAuth0();

  let location = useLocation();

  const className = background === colors.white ? 'white-bg-head' : 'gray-bg-head'

  return (
    <IonGrid className={className}>
      <div className="back-to-section">
        {hasBackButton && <IonImg onClick={() => backClick()} className="icon-back" src={ICON_BACK} />}
        <Logo />
        {isAuthenticated && location.pathname !== USER_INFORMATION_ROUTE.path &&
          <MenuDropdownButton/>}
        {/*{isAuthenticated && <LogOutButton logout={appLogout} />}*/}
      </div>
      {!isAuthenticated && previewSteps &&
        <StepsComponent
          activeStep={activeStep}
          stepNames={stepNames || defaultStepNames}
        />
      }
    </IonGrid>
  )
}
