import App from "./product/_init";
import {createRoot} from "react-dom/client";
import TagManager from 'react-gtm-module'
import {SnackbarProvider} from "notistack";

const container = document.getElementById("root");
const root = createRoot(container!);

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID
}
TagManager.initialize(tagManagerArgs)

root.render(
  <SnackbarProvider
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    autoHideDuration={5000}
  >
    <App/>
  </SnackbarProvider>
);
