import LoadingModel from "../../models/loading";
import {
  LoadingActionTypes,
  CLOSE_LOGIN_MODAL,
  LOADING_INIT_STATE,
  LOADING_MODAL,
  SHOW_LOGIN_MODAL
} from "./types";

export default function loadingReducer(
  state: LoadingModel = LOADING_INIT_STATE,
  action: LoadingActionTypes
): LoadingModel {
  const { type } = action;

  switch (type) {
    case LOADING_MODAL:
      return {
        ...state,
        isLoadingModal: true,
        loadingText: action.loadingText || LOADING_INIT_STATE.loadingText,
        hasError: false
      };
    case SHOW_LOGIN_MODAL:
      return {
        ...state,
        showLoginModal: true
      };
    case CLOSE_LOGIN_MODAL:
      return {
        ...state,
        showLoginModal: false
      };
    default:
      break;
  }

  const isLoading = /(.*)(LOGGING|PROCESSING|UPDATING|LOADING)(.*)/.exec(type);
  const hasLoaded = /(.*)_(SUCCESS|SCREEN_STATE|FAILURE)/.exec(type);

  if (isLoading)
    return {
      ...state,
      isLoading: true,
      loadingText: action.loadingText || LOADING_INIT_STATE.loadingText,
      hasError: false
    };
  if (hasLoaded) {
    if (type.includes("FAILURE")) {
      return {
        ...state,
        isLoading: false,
        isLoadingModal: false,
        hasError: true,
        err: action.err || "ERROR.UNKNOWN"
      };
    }
    return {
      ...state,
      isLoadingModal: false,
      isLoading: false,
      hasError: false
    };
  }

  return state;
}
