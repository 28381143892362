import styled from 'styled-components';
import { IonToolbar } from '@ionic/react';

import { ENUMS } from '../../../styles';

export const Toolbar = styled(IonToolbar)`
  @media screen and (max-width: ${ENUMS.SPLIT_PANE_WIDTH}) {
    .can-go-back ion-menu-button {
      display: none;
    }
  }

  @media screen and (min-width: ${ENUMS.SPLIT_PANE_MIN_WIDTH}) {
    .can-go-back > ion-header ion-back-button {
      display: none !important;
    }
  }
`;
