export const myStyle = {
  coverageStatusInfo: {
    background: '#F8D859',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 4,
    marginBottom: 15,
    marginTop: 10,
    '& p': {
      marginTop: 0,
      marginBottom: 10,
      color: '#2D2D2D',
      fontSize: 16,
      letterSpacing: '0.4px',
      fontFamily: 'UrbanistMedium',
    },
    '& h2': {
      margin: 0,
      color: '#2D2D2D',
      fontSize: 24,
      letterSpacing: '0.4px',
      fontFamily: 'UrbanistSemiBold',
    }
  },
  coverageStatusInfoContent: {
    paddingTop: 10,
    paddingBottom: 10,
    '& p': {
      marginTop: 0,
      marginBottom: 10,
      color: '#2D2D2D',
      fontSize: 16,
      letterSpacing: '0.4px',
      fontFamily: 'UrbanistMedium',
    },
    '& h2': {
      margin: 0,
      color: '#2D2D2D',
      fontSize: 24,
      letterSpacing: '0.4px',
      fontFamily: 'UrbanistSemiBold',
    }
  }
}
