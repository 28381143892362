import React, {Fragment} from "react";
import { UniversalButton } from "../index";

interface Props {
  buttons: any;
}

export const UniversalButtonGroup: React.FC<Props> = ({ buttons = [] }) => (
  <Fragment>
    {buttons.length && buttons.map((button, idx) =>
      <UniversalButton
        key={idx}
        {...button}
      />
    )}
  </Fragment>
);
