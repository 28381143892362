import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {IonSelect, IonSelectOption, IonRow, IonCol, IonImg} from "@ionic/react";
import {fetchInsuranceCompanies} from "../../../business/redux/reducers/insuranceCompanies/actions";
import {IonInput} from "@ionic/react";
import {toastWarning} from "../../../business/redux/reducers/toaster/actions";
import ReduxStore from "../../../business/redux";
import {StoreModel} from "../../../business/models/store";
import {Heading} from "../heading";
import {QuestionMarkIcon} from "../icons";
import {makeStyles} from "@material-ui/core/styles";
import {style} from "./styles";
import {InformationModal} from "../modals";

interface InsuranceCompaniesModel {
  onSelect?: any;
  step: number;
  selectedInsuranceCompany: any;
  fileUrl: any;
  setFileUrl: any;
  resident?: any;
  openInsureInfo?: any;
}

const InsuranceCompanies: React.FC<InsuranceCompaniesModel> = ({
                                                                 onSelect,
                                                                 step,
                                                                 fileUrl,
                                                                 setFileUrl,
                                                                  selectedInsuranceCompany,
                                                                 openInsureInfo,
                                                                 resident = null
                                                               }) => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = makeStyles(() => style)()
  const dispatch = useDispatch()
  const {insuranceCompanies} = useSelector((state: StoreModel) => state)
  useEffect(() => {
    dispatch<any>(fetchInsuranceCompanies())
  }, []);
  let companies = insuranceCompanies?.companies || []
  const handleChange = (type = "") => (e) => {
    if (e.target.name === 'standard' && fileUrl) {
      setFileUrl("")
      ReduxStore.dispatch(toastWarning({className: "small", text: 'MESSAGE.REUPLOAD_POLICY_FILE'}));
    }
    const insuranceCompanyText = companies.filter(el => el.key === e.target.value)[0]?.text
    const data = (e.target.value === "other" || e.target.name === "other")
      ?
      {
        key: "",
        value: (type === 'select' ? '' : e.target.value),
        text: "Other"
      }
      :
      {
        key: "standard",
        value: e.target.value,
        text: insuranceCompanyText
      };
    onSelect(data, selectedInsuranceCompany)
  };

  return (
    <>
      <Heading text={'Please add information about your policy:'} hasSuccessLogo={false}/>
      <IonRow>
        <IonCol className="ion-padding-top ion-padding-bottom">
          {/*<h6 className={classes.fileHeader}>Choose insurance company from the list <QuestionMarkIcon onClick={openInsureInfo} /></h6>*/}
          <h6 className={classes.fileHeader}>Choose insurance company from the <span>list <QuestionMarkIcon
            onClick={() => setIsOpen(true)}/></span></h6>
          <IonSelect
            label-placement="floating"
            interface="popover"
            placeholder={!resident?.auth0_sub && selectedInsuranceCompany.text ? selectedInsuranceCompany.text : "Please select"}
            className="custom-select"
            onIonChange={handleChange('select')}
            name="standard"
            interfaceOptions={{
              alignment: "start",
            }}
          >
            {
              companies && companies.length && companies.map(insuranceCompany =>
                <IonSelectOption
                  key={insuranceCompany.text}
                  value={insuranceCompany.key}
                >
                  {insuranceCompany.text}
                </IonSelectOption>
              )
            }
          </IonSelect>
        </IonCol>
      </IonRow>
      {
        selectedInsuranceCompany.text === "Other" && (step < 4) &&
        <IonRow>
          <IonCol className="ion-padding-bottom">
            <IonInput
              mode="ios"
              placeholder="Insurance Company Name"
              name={"other"}
              className="ion-border ion-border-radius4 custom-ion-input"
              onIonChange={handleChange('')}
              value={selectedInsuranceCompany.value}
            />
          </IonCol>
        </IonRow>
      }

      <InformationModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        headerText={'What is my insurance company?'}
        contextText={
        <p>
          The company name of your insurance provider should be entered here.
          This is where you purchased renters insurance from,
          and the name can be found on your policy documents.
        </p>
        }
      />
    </>
  );
};

export default InsuranceCompanies
