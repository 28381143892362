import { Dispatch } from 'redux';
import { LOADING } from '../../../../core/business/redux/loading/types';
import { LOAD_STORAGE } from '../../../../core/business/redux/localStorage/types';
import { localStorage, theme } from '../../../../core/business/services';
import { ROUTES } from '../../enums';
import { AppModel } from '../';
import { INIT_SUCCESS } from './types';

function restoreTheme(localTheme) {
  theme.switchTheme(localTheme);
}

const loadStorage = async (dispatch) => {
  const storageData = await localStorage.getStorageData();
  const columns = storageData.columns ? JSON.parse(storageData.columns) : [];
  const dateFilter = storageData.dateFilter ? JSON.parse(storageData.dateFilter) : undefined;

  dispatch({
    type: LOAD_STORAGE,
    localStorage: {
      ...storageData,
      columns,
      dateFilter,
    },
  });
}

const shouldForcePublic = () => {
  const path = window.location.pathname;
  return ROUTES.PUBLIC_ROUTES.find((route) => path.includes(route.path));
};


export const loadApp = () => async (dispatch: Dispatch, getState: () => AppModel) => {

  dispatch({
    type: LOADING,
    loadingText: "PROGRESS.INITIALIZING"
  });

  if (!shouldForcePublic())
    await loadStorage(dispatch);

  const { localStorage } = getState();

  restoreTheme(localStorage.theme);

  dispatch({ type: INIT_SUCCESS });
};
