import {  LOGIN_SUCCESS } from '../../../../core/business/redux/auth/types';

import { InitModel, LOAD_HEADERS, INIT_STATE, INIT_SUCCESS } from './types';

export function initReducer(state: InitModel = INIT_STATE, action): InitModel {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        headers: {
          ...state.headers,
        },
      };
    case INIT_SUCCESS:
      return {
        ...state,
        initSuccess: true,
      };
    case LOAD_HEADERS:
      return {
        ...state,
        headers: {
          ...state.headers,
          ...action.headers,
        },
      };
    default:
      return state;
  }
}
