import {
  PropertyNamesActionTypes,
  PropertyNamesReducerType,
  PROPERTY_NAMES_INIT_STATE,
  FETCH_PROPERTY_NAMES_SUCCESS,
  FETCH_PROPERTY_NAMES_FAILURE,
} from './types';

export default function propertyNamesReducer(
  state: PropertyNamesReducerType = PROPERTY_NAMES_INIT_STATE,
  action: PropertyNamesActionTypes,
): PropertyNamesReducerType {
  switch (action.type) {
    case FETCH_PROPERTY_NAMES_SUCCESS:
      return action.propertyNames
    case FETCH_PROPERTY_NAMES_FAILURE:
      return PROPERTY_NAMES_INIT_STATE
    default:
      return state;
  }
}
