import RouterModel from '../../models/router';
import { RouterActionTypes, SET_ROUTE, ROUTER_INIT_STATE, SET_SCREEN_STATE } from './types';

export default function routerReducer(
  state: RouterModel = ROUTER_INIT_STATE,
  action: RouterActionTypes,
): RouterModel {
  switch (action.type) {
    case SET_ROUTE:
      return {
        currentRoute: action.payload,
        previousRoute: state.currentRoute,
        params: action.params,
        previousParams: {
          ...state.params,
        },
        screenState: '',
      };
    case SET_SCREEN_STATE:
      return {
        ...state,
        screenState: action.payload,
      };
    default:
      return state;
  }
}
