import RouterModel from '../../models/router';

export const SET_ROUTE = 'SET_ROUTE';
export const SET_SCREEN_STATE = 'SET_SCREEN_STATE';

// Action Type
export interface SetRouteType {
  type: typeof SET_ROUTE | typeof SET_SCREEN_STATE;
  payload: string;
  params?: any;
  previousParams?: any;
}

export type RouterActionTypes = SetRouteType;

export const ROUTER_INIT_STATE: RouterModel = {
  currentRoute: '',
  previousRoute: '',
  screenState: '',
};
