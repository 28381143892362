import React from 'react';
import {IonRow, IonCol, IonImg} from "@ionic/react";
import {ICON_DONE} from '../../../images';

interface Props {
  logo?: any;
  style?: any;
}

export const SuccessLogo: React.FC<Props> = ({logo = ICON_DONE, style = {width: 80}}) => (
  <IonRow>
    <IonCol className="logo-box">
      <IonImg src={logo} style={style}/>
    </IonCol>
  </IonRow>
)
