import _ from 'lodash';
import React from 'react';
import { Select, FormControlProps, FormHelperText, OutlinedInput, NativeSelect } from '@mui/material';
import { Field, FastField } from 'formik';

import { configs } from '../../../../configs';
import intl from '../../../intl';
import { input } from '../../utils';

import { BSLabel, FormControlWrapper } from './styles';

interface SelectInputProps {
  icon?: string;
  label?: string;
  labelText?: string;
  name: string;
  formik?: any;
  size?: string;
  disabled?: boolean;
  readonly?: boolean;
  placeholder?: string;
  shrink?: boolean;
  native?: boolean;
  position?: FormControlProps['variant'];
  multiSelect?: boolean;
  options: React.ReactNode | null;
  defaultValue?: string | number | null;
  handleChange?: (e) => void;
  disableFastField?: boolean;
}

const defaultProps: SelectInputProps = {
  icon: '',
  label: '',
  labelText: '',
  name: '',
  formik: {},
  native: false,
  size: configs.display.inputSize,
  disabled: false,
  readonly: false,
  placeholder: '',
  shrink: undefined,
  position: undefined,
  multiSelect: false,
  options: null,
  defaultValue: null,
  handleChange: () => {},
  disableFastField: false,
};

const SelectInput: React.FC<SelectInputProps> = ({
  icon,
  label,
  labelText,
  name,
  formik,
  size,
  disabled,
  readonly,
  native,
  placeholder,
  shrink,
  position,
  multiSelect,
  options,
  defaultValue,
  disableFastField,
  handleChange,
  ...props
}) => {
  const onChange = (e) => {
    if (formik.handleChange) formik.handleChange(e);
    if (handleChange) handleChange(e);
  };

  const errorKey = _.get(formik.errors, name);
  const isTouched = _.get(formik.touched, name) !== undefined;
  const hasError = isTouched && errorKey !== undefined;
  const errorMsg = hasError ? intl(`INPUT.ERROR.${errorKey}`) : undefined;

  const inputSize = size === 'small' ? 'small' : 'medium';

  const labelProps =
    position || shrink
      ? {
          label: labelText || intl(label),
          ...((shrink || !!native) && {
            input: <OutlinedInput name={name} label={labelText || intl(label)} notched />,
          }),
        }
      : {};
  const variant = position || 'outlined';

  const FormField = disableFastField ? Field : FastField;

  const renderSelect = (form) => {
    return (
      <Select
        {...props}
        {...labelProps}
        labelId={`label_${name}`}
        multiple={multiSelect}
        name={name}
        disabled={disabled}
        placeholder={intl(placeholder) || undefined}
        onChange={onChange}
        displayEmpty
        value={_.get(form.values, name) || ''}>
        {input.generateSelectOptions(options, true)}
      </Select>
    )
  }

  const renderNative = (form) => (
    <NativeSelect
      {...props}
      {...labelProps}
      name={name}
      disabled={disabled}
      placeholder={intl(placeholder) || undefined}
      onChange={onChange}
      value={_.get(form.values, name) || ''}>
      {input.generateNativeOptions(options, true)}
    </NativeSelect>
  );

  return (
    <>
      <FormField name={name}>
        {({form}) => {
          console.log('---------------', form)
          return (
            <FormControlWrapper size={inputSize} error={hasError} variant={variant} fullWidth>
              <BSLabel id={`label_${name}`} className={!position && 'bs-label'} shrink={shrink}>
                {labelText || intl(label)}
              </BSLabel>
              {native ? renderNative(form) : renderSelect(form)}
              <FormHelperText>{errorMsg}</FormHelperText>
            </FormControlWrapper>
          )
        }}
      </FormField>
    </>
  );
};

SelectInput.defaultProps = defaultProps;

export default SelectInput;
