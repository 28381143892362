import React, {useEffect, useState} from "react";
import {HeaderSection} from "../../components/primitive/headerSection";
import {IonCol, IonContent, IonFooter, IonGrid, IonInput, IonLabel, IonRow, IonText} from "@ionic/react";
import {Wrapper} from "../../../core/styles";
import {makeStyles} from "@material-ui/core/styles";
import {style} from "../../components/primitive/coverageStatusSection/style";
import {colors} from "../../business/constants/global";
import {UniversalButtonGroup} from "../../components/primitive/buttons";
import {useHistory} from "react-router-dom";
import {ACCOUNT_SETTINGS_EDIT_ROUTE, ACCOUNT_SETTINGS_ROUTE, USER_INFORMATION_ROUTE} from "../../business/enums/routes";
import {contactFormStyle} from "../contactInformation/styles";
import {useAuth0} from "@auth0/auth0-react";
import {getResidentByAuth, updateResidentProfile} from "../../business/redux/reducers/resident/actions";
import {useDispatch, useSelector} from "react-redux";
import {StoreModel} from "../../business/models/store";

export const AccountSettingsEdit: React.FC = (): JSX.Element => {

  const {user} = useAuth0();

  const dispatch = useDispatch();

  const {resident} = useSelector((state: StoreModel) => state);

  const [updatedData, setUpdatedData] = useState(null);
  const [sent, setSent] = useState(false);

  useEffect(() => {
    setUpdatedData({
      // @ts-ignore
      resident_first_name: resident?.resident?.resident_first_name,
      resident_last_name: resident?.resident?.resident_last_name,
      email: resident?.resident?.email,
      phone: resident?.resident?.phone
    })
  }, [
    resident?.resident?.resident_first_name,
    resident?.resident?.resident_last_name,
    resident?.resident?.email,
    resident?.resident?.phone,
  ]);

  useEffect(() => {
    // @ts-ignore
    dispatch(getResidentByAuth(user?.sub))
  }, []);

  // @ts-ignore
  const classes = makeStyles(() => style(colors.cyan))();

  // @ts-ignore
  const formClasses = makeStyles(() => contactFormStyle)();

  let history = useHistory();

  const buttons = [
    {
      color: colors.cyan,
      value: "BUTTON.SAVE",
      // @ts-ignore
      disabled: !(updatedData?.phone?.length === 10 && updatedData?.email && updatedData?.resident_first_name && updatedData?.resident_last_name),
      // onClick: () => {}
      // @ts-ignore
      onClick: () => dispatch(updateResidentProfile(
        resident?.resident?.uuid,
        updatedData,
        () => history.push(ACCOUNT_SETTINGS_ROUTE.path)
      ))
    },
    {
      value: "BUTTON.CANCEL",
      color: colors.white,
      onClick: () => history.push(ACCOUNT_SETTINGS_ROUTE.path),
    },
  ]

  // @ts-ignore
  const changeHandler = e => setUpdatedData(prev => ({...prev, [e.target.name]: e.target.value}))

  const errorText = (message) => (
    <IonText color="danger" className="ion-margin-top">
      <span role="alert">{message}</span>
    </IonText>
  )

  return (
    <IonContent>
      <Wrapper className="app-container ion-padding">

        <IonGrid className="gray-bg-head ion-margin-top">
          <HeaderSection backClick={() => history.push({pathname: ACCOUNT_SETTINGS_ROUTE.path})} hasBackButton={true} previewSteps={false}/>

          <IonRow>
            <IonCol className={'ion-text-center'}>
              <h2 className={classes.mediumTitle}>Account Settings</h2>
            </IonCol>
          </IonRow>

        </IonGrid>




        <div className={formClasses.inputGroup} style={{marginTop: 20}}>
          <IonLabel>First Name</IonLabel><br/>
          <IonInput
            mode="ios"
            type={'text'}
            // @ts-ignore
            value={updatedData?.resident_first_name || ""}
            name={"resident_first_name"}
            onIonInput={changeHandler}
            className="ion-border ion-border-radius4"
          />
          {/*@ts-ignore*/}
          {/*{userVerified.sent && checkPhoneOrEmail?.status !== 'success' && errorText(checkPhoneOrEmail?.message)}*/}
          {/*{checkPhoneOrEmail && checkPhoneOrEmail?.status !== "success" && errorText(checkPhoneOrEmail?.message || "")}*/}
          {/*{userVerified.type === 'phone_number' && <p className='ion-padding-start'>Mobile format: 5555551234</p>}*/}
        </div>
        <div className={formClasses.inputGroup} style={{marginTop: 20}}>
          <IonLabel>Last Name</IonLabel><br/>
          <IonInput
            mode="ios"
            type={'text'}
            // @ts-ignore
            value={updatedData?.resident_last_name || ""}
            name={"resident_last_name"}
            onIonInput={changeHandler}
            className="ion-border ion-border-radius4"
          />
          {/*@ts-ignore*/}
          {/*{userVerified.sent && checkPhoneOrEmail?.status !== 'success' && errorText(checkPhoneOrEmail?.message)}*/}
          {/*{checkPhoneOrEmail && checkPhoneOrEmail?.status !== "success" && errorText(checkPhoneOrEmail?.message || "")}*/}
          {/*{userVerified.type === 'phone_number' && <p className='ion-padding-start'>Mobile format: 5555551234</p>}*/}
        </div>
        <div className={formClasses.inputGroup} style={{marginTop: 20}}>
          <IonLabel>E-mail</IonLabel><br/>
          <IonInput
            readonly
            mode="ios"
            type={'text'}
            // @ts-ignore
            value={updatedData?.email || ""}
            name={"email"}
            onIonInput={changeHandler}
            className="ion-border ion-border-radius4"
          />
          {/*@ts-ignore*/}
          {/*{userVerified.sent && checkPhoneOrEmail?.status !== 'success' && errorText(checkPhoneOrEmail?.message)}*/}
          {/*{checkPhoneOrEmail && checkPhoneOrEmail?.status !== "success" && errorText(checkPhoneOrEmail?.message || "")}*/}
          {/*{userVerified.type === 'phone_number' && <p className='ion-padding-start'>Mobile format: 5555551234</p>}*/}
        </div>
        <div className={formClasses.inputGroup} style={{marginTop: 20}}>
          <IonLabel>Cell</IonLabel><br/>
          <IonInput
            mode="ios"
            type={'number'}
            // @ts-ignore
            value={updatedData?.phone || ""}
            name={"phone"}
            onIonInput={e => {
              setSent(true)
              changeHandler(e)
            }}
            className="ion-border ion-border-radius4"
          />
          <p className='ion-padding-start'>Phone format: 5555551234</p>
          {/*@ts-ignore*/}
          {sent && updatedData?.phone?.length !== 10 && errorText('Phone number must be at least 10 digits.')}
          {/*{userVerified.sent && checkPhoneOrEmail?.status !== 'success' && errorText(checkPhoneOrEmail?.message)}*/}
          {/*{checkPhoneOrEmail && checkPhoneOrEmail?.status !== "success" && errorText(checkPhoneOrEmail?.message || "")}*/}
          {/*{userVerified.type === 'phone_number' && <p className='ion-padding-start'>Mobile format: 5555551234</p>}*/}
        </div>

        <IonFooter>
          <UniversalButtonGroup
            buttons={buttons}
          />
        </IonFooter>
      </Wrapper>
    </IonContent>
  );
};
