import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {PasswordInformation} from "../../primitive/passwordInformation";
import {UniversalButton} from "../../primitive/buttons";
import {saveResident} from "../../../business/redux/reducers/resident/actions";
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from "react-router-dom";
import {Formik, Form} from "formik";
import {StoreModel} from "../../../business/models/store";
import {IonCol, IonFooter, IonGrid, IonIcon, IonImg, IonItemDivider, IonRow} from "@ionic/react";
import {input} from "../../../../core/business/helpers";
import {PASSWORD_FORM} from "./passwordForm";
import {INPUT} from "../../../../core/business/enums";
import {eyeOffOutline, eyeOutline} from "ionicons/icons";
import {dialog} from "../../../../core/components/reusables";
import {passwordConfirmationStyle} from './styles';
import TextField from "@mui/material/TextField";
import {ICON_ADVICE} from "../../../images";

export const FormView: React.FC = () => {
  let history = useHistory();
  const useStyles = makeStyles(() => passwordConfirmationStyle);
  const classes = useStyles();
  const dispatch = useDispatch()
  const [type, setType] = useState(true);
  const [password, setPassword] = useState("");

  const initialValues = input.deriveFormInitialValues(PASSWORD_FORM, { password: "" });

  const passwordType = type ? "PASSWORD" : "TEXT";

  const { resident } = useSelector((state: StoreModel) => state)

  const validation = [
    input.requiredValidation,
    input.passwordLengthValidation,
    input.hasUpperCaseValidation,
    input.hasLowerCaseValidation,
    input.hasNumberValidation
  ];

  const filteredErrors = validation.filter(err => err.condition(password));
  return (
    <Formik
      initialStatus={"password"}
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={() => {
        if ( filteredErrors.length ) {
          return dialog.alert({
            title: 'ERROR.SAVE',
            message: 'MESSAGE.SAVE_FAIL',
          });
        }
        return dispatch(saveResident( resident?.resident?.uuid, password, history ))
      }}
    >
      { formik => (
        <>
          <Form>
            <span className="password-eye-icon">
              <IonIcon size="small" icon={type ? eyeOutline : eyeOffOutline} onClick={() => setType(!type)}
                       color="secondary" />
            </span>
            <TextField className="full-width" onChange={(e) => setPassword(e.target.value)} type={INPUT[`${passwordType}`]} />

          </Form>
          <PasswordInformation errors={filteredErrors} />
          <IonItemDivider className="divider10"></IonItemDivider>
          <IonGrid className="ion-padding-top ion-padding-bottom pl-0">
            <IonRow className={classes.headingWithIcon}>
              <IonCol className="pl-0 ion-padding-end" size="auto">
                <IonImg className={classes.headingIcon} src={ICON_ADVICE}/>
              </IonCol>
              <IonCol size="8">
                <h2 className={classes.headingText}><strong>Advice:</strong> Bookmark this page to make logging in easier next time.  </h2>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonFooter>
            <UniversalButton
              value={"BUTTON.DONE"}
              event={{
                event: 'created-username-and-password',
                email: resident?.resident?.email,
                domain: window.location.hostname
              }}
              onClick={() => formik.handleSubmit()}
              disabled={filteredErrors.length > 0}
            />
          </IonFooter>
        </>
      )}
    </Formik>
  );
};
