import _ from 'lodash';
import React from 'react';
import { IonItem, IonLabel, IonIcon, IonCheckbox } from '@ionic/react';
import { FastField, Field, ErrorMessage } from 'formik';

import intl from '../../../intl';
import { Styles } from '../../../styles';

interface CheckboxProps {
  icon?: string;
  label?: string;
  labelText?: string;
  name: string;
  disabled?: boolean;
  readonly?: boolean;
  position?: string;
  formik?: any;
  slot?: string;
  value?: any;
  disableFastField?: boolean;
  handleChange?: (e) => void;
}

const defaultProps: CheckboxProps = {
  icon: '',
  label: '',
  labelText: '',
  name: '',
  disabled: false,
  readonly: false,
  position: 'floating',
  formik: {},
  slot: 'end',
  value: 'on',
  disableFastField: false,
  handleChange: () => {},
};

const Checkbox: React.FC<CheckboxProps> = ({
  icon,
  label,
  labelText,
  name,
  disabled,
  readonly,
  position,
  formik,
  slot,
  value,
  disableFastField,
  handleChange,
  ...props
}) => {
  const onChange = (e) => {
    if (formik.setFieldValue) formik.setFieldValue(name, e.detail.checked);
    if (handleChange) handleChange(e);
  };

  const FormField = disableFastField ? Field : FastField;
  const ErrMsg: any = ErrorMessage;

  return (
    <>
      <FormField name={name}>
        {({ form }) => {
          return (
            <>
              <IonItem className={readonly ? 'readonly' : ''}>
                {icon !== '' && <IonIcon slot="start" icon={icon} />}
                {(label !== '' || labelText !== '') && <IonLabel>{labelText || intl(label)}</IonLabel>}

                <ErrMsg name={name}>
                  {(e) => <Styles.InputError>{intl(`INPUT.ERROR.${e}`)}</Styles.InputError>}
                </ErrMsg>
                <IonCheckbox
                  {...props}
                  name={name}
                  disabled={disabled}
                  slot={slot}
                  onIonChange={onChange}
                  checked={!!_.get(form.values, name)}
                  value={value}></IonCheckbox>
              </IonItem>
            </>
          );
        }}
      </FormField>
    </>
  );
};

Checkbox.defaultProps = defaultProps;

export default Checkbox;
