import React, {useRef} from 'react';
import {UniversalButton} from "../../buttons";
import {Heading} from "../../heading";
import {enterAnimation, leaveAnimation} from "../helpers";
import {IonFooter, IonModal} from '@ionic/react';
import {InformationModalInterface} from "./interfaces";
import './style.css';

export const InformationModal: React.FC<InformationModalInterface> = ({
                                                                        isOpen,
                                                                        setIsOpen,
                                                                        headerText = '',
                                                                        contextText,
                                                                        hasCloseButton = true
                                                                      }) => (
  <IonModal
    id="information-modal"
    isOpen={isOpen}
    ref={useRef<HTMLIonModalElement>(null)}
    onWillDismiss={() => setIsOpen(false)}
    enterAnimation={enterAnimation}
    leaveAnimation={leaveAnimation}
  >
    <div className="wrapper">
      <Heading hasSuccessLogo={false} text={headerText}/>
      <>{contextText}</>
      {hasCloseButton &&
        <IonFooter>
          <UniversalButton
            value={'BUTTON.CLOSE'}
            onClick={() => setIsOpen(false)}
            disabled={false}
          />
        </IonFooter>}
    </div>
  </IonModal>
);
