import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchResident} from "../../business/redux/reducers/resident/actions";
import {useParams} from "react-router-dom";
import {ErrorComponent} from "../../components/errors";
import {HeaderSection} from "../../components/primitive/headerSection";
import {StoreModel} from "../../business/models/store";
import {IdParam} from "../../business/types";
import {IonCol, IonContent, IonRow} from "@ionic/react";
import {Wrapper} from "../../../core/styles";

export const Chat: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const params = useParams<IdParam>();
  useEffect(() => {
    dispatch<any>(fetchResident(params));
  }, []);

  const { resident } = useSelector((state: StoreModel) => state);
  const resident_first_name = resident?.resident?.resident_first_name;

  return (
    <IonContent>
      {resident?.success === false && <ErrorComponent />}
      {resident?.success === true && (
        <Wrapper className="app-container ion-padding">
              <HeaderSection previewSteps={false} hasBackButton={true} />
              <IonRow>
                <IonCol className="ion-text-center">
                  <h2>
                    Resident App Chat
                  </h2>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol className="ion-text-center">
                  <h2>Welcome, {resident_first_name}</h2>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol className="ion-text-center">
                  <p>Your one-on-one chat will start shortly</p>
                </IonCol>
              </IonRow>
        </Wrapper>
      )}
    </IonContent>
  );
};
