const globalStyle = {
  borderStyle: {
    borderWidth: 4,
    borderStyle: 'solid',
    borderTopLeftRadius: 60,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 60,
    marginBottom: 15,
    padding: 0,
  },
  headerTitle: {
    color: '#fff',
    margin: '5px 0 5px 20px',
    fontWeight: 500,
    fontSize: 20,
  },
  whiteRound: {
    backgroundColor: '#fff',
    width: 116,
    padding: 18,
    borderRadius: '50%',
    position: 'relative',
    marginBottom: -50,
  },
  headerStyle: {
    borderRadius: '60px 10px 0 0',
  },
  paragraph: {
    color: '#2D2D2D',
    fontSize: 16,
    maxWidth: 215,
    margin: '25px auto 5px',
    letterSpacing: '0.002em'
  },
  textBold: {
    fontWeight: 500,
    fontSize: 24,
    margin: 0,
  },
  iconQuestion: {
    width: 24,
    cursor: 'pointer',
  },
  mediumTitle: {
    fontFamily: 'InfraMedium',
    fontSize: 24,
    margin: 0,
  },
};

//isMobile
const globalStyleMobile = {
  borderStyle: {
    borderWidth: 4,
    borderStyle: 'solid',
    borderTopLeftRadius: 50,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 60,
    marginBottom: 15,
    padding: 0,
    "& .small-grey-separator": {
      marginTop: 10,
      marginBottom: 10,
    },
    "& .ion-padding": {
      padding: 10,
    },
    "& ion-button": {
      minHeight: 50,
    },
  },
  headerTitle: {
    color: '#fff',
    margin: '5px 0 5px 20px',
    fontWeight: 500,
    fontSize: 20,
  },
  whiteRound: {
    backgroundColor: '#fff',
    width: 86,
    padding: 18,
    borderRadius: '50%',
    position: 'relative',
    marginBottom: -45,
    "& > ion-img": {
      width: 50,
    }
  },
  headerStyle: {
    borderRadius: '60px 10px 0 0',
  },
  paragraph: {
    color: '#2D2D2D',
    fontSize: 16,
    maxWidth: 215,
    margin: '15px auto 5px',
    letterSpacing: '0.002em'
  },
  textBold: {
    fontWeight: 500,
    fontSize: 18,
    margin: 0,
  },
  iconQuestion: {
    width: 24,
    cursor: 'pointer',
  },
  mediumTitle: {
    fontFamily: 'InfraMedium',
    fontSize: 18,
    margin: 0,
  },
};

export const style = (color, isMobile = false) => isMobile
  ? ({
    ...globalStyleMobile,
    borderStyle: {
      ...globalStyleMobile.borderStyle,
      borderColor: color,
    },
    headerStyle: {
      ...globalStyleMobile.headerStyle,
      backgroundColor: color,
    }
  })
  : ({
    ...globalStyle,
    borderStyle: {
      ...globalStyle.borderStyle,
      borderColor: color,
    },
    headerStyle: {
      ...globalStyle.headerStyle,
      backgroundColor: color,
    }
  })
