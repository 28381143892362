import {Dispatch} from "redux";
import {configs} from "../../../../../configs";
import * as resident from "../../../mocks/resident.json";
import * as setToPdlwMock from "../../../mocks/setToPdlw.json";
import * as getResidentByAuthMock from "../../../mocks/getResidentByAuth.json";
import {fetchApi, getUrl, handleApiFail, withAxios} from "../../../../../core/business/services/api";
import {LOADING, LOADING_MODAL} from "../../../../../core/business/redux/loading/types";
import {
  FETCH_RESIDENT_SUCCESS,
  SAVE_RESIDENT_FAILURE,
  SAVE_RESIDENT_SUCCESS,
  SELF_SIGN_UP_FAILURE,
  SELF_SIGN_UP_SUCCESS
} from "./types";
import {getStorageObj, setStorageObj} from "../../../../../core/business/services/local.storage";
import {IdParam} from "../../../types";
import {CLEAR_LOADING_BY_MIDDLE_TYPE, SET_LOADING_BY_MIDDLE_TYPE} from "../loadingByType/types";
import {
  COMMUNITY_INFORMATION_ROUTE,
  COVERAGE_ROUTE,
  PASSWORD_APPROVED_ROUTE,
} from "../../../enums/routes";
import {startUrls} from "../../../constants/startUrls";

export const fetchResident = (param: IdParam) => async (dispatch: Dispatch) => {

  dispatch({
    type: SET_LOADING_BY_MIDDLE_TYPE,
    loadingData: {
      loading: true,
      modalData: {
        message: "Please wait"
      }
    }
  });

  if (!param.id) {
    dispatch({
      type: FETCH_RESIDENT_SUCCESS,
      resident: {
        success: false,
        message: "MESSAGE.NOT_PARAMS"
      }
    });
    await setStorageObj("resident", "");
    return;
  }
  const { id } = param;
  const response = await fetchApi({
    url: configs.api.resident.general + "/" + id,
    method: "GET",
    mockData: { success: true, resident }
  });
  await setStorageObj("resident", response);

  dispatch({
    type: CLEAR_LOADING_BY_MIDDLE_TYPE,
    loadingData: null
  });

  dispatch({
    type: FETCH_RESIDENT_SUCCESS,
    resident: response
  });
};

export const syncLocalResident = () => async dispatch => {

  dispatch({
    type: LOADING,
    loadingText: "PROGRESS.LOADING"
  });

  const response = await getStorageObj("resident");

  if (!response) return;

  dispatch({
    type: FETCH_RESIDENT_SUCCESS,
    resident: response
  });
};

export const saveResident = (id, password, history): any => async (dispatch: Dispatch) => {
  dispatch({
    type: LOADING_MODAL,
    loadingText: "PROGRESS.SAVING"
  });

  const { general, setPassword } = configs.api.resident;
  const url = `${general}/${id}/${setPassword}`;
  const response = await fetchApi({
    url,
    param: { password },
    mockData: { success: true },
    method: "POST"
  });

  if (!response || !response?.success) {
    handleApiFail(dispatch, SAVE_RESIDENT_FAILURE, response, "MESSAGE.SAVE_FAIL", true);
  } else {
    history.push({
      pathname: PASSWORD_APPROVED_ROUTE.path,
      state: history.location.state
    })
  }

  dispatch({type: SAVE_RESIDENT_SUCCESS});
};

export const fetchSetToPdlw = (uuid, callback = () => {}) => async dispatch => {
  dispatch({
    type: SET_LOADING_BY_MIDDLE_TYPE,
    loadingData: {
      loading: false,
      modalData: {
        message: "Please wait"
      }
    }
  });
  const { residentApp, setToPdlw } = configs.api.resident;
  const url = `${residentApp}/${setToPdlw}/${uuid}`;
  const method = "POST";
  const mockData = { success: true, resident: setToPdlwMock };
  const response = await fetchApi({ url, method, mockData });

  await setStorageObj("resident", response);

  if (!response || !response?.success) {

    handleApiFail(dispatch, SAVE_RESIDENT_FAILURE, response, "MESSAGE.SAVE_FAIL", true);
  }
  // else {
  //   dispatch({
  //     type: SAVE_RESIDENT_SUCCESS,
  //     resident: response
  //   });
  // }

  dispatch({
    type: FETCH_RESIDENT_SUCCESS,
    resident: response
  })

  dispatch({
    type: CLEAR_LOADING_BY_MIDDLE_TYPE,
    loadingData: null
  });

  callback();
};

export const getResidentByAuth = sub => async dispatch => {
  dispatch({
    type: SET_LOADING_BY_MIDDLE_TYPE,
    loadingData: {
      loading: false,
      modalData: {
        message: "Please wait"
      }
    }
  });
  const { residentApp, getResidentByAuth } = configs.api.resident;
  const url = `${residentApp}/${getResidentByAuth}/${sub}`;
  const method = "GET";
  const mockData = { success: true, resident: getResidentByAuthMock };
  const response = await fetchApi({ url, method, mockData });

  dispatch({
    type: CLEAR_LOADING_BY_MIDDLE_TYPE,
    loadingData: null
  });

  await setStorageObj("resident", response);

  dispatch({
    type: FETCH_RESIDENT_SUCCESS,
    resident: response
  });
};

export const saveResidentCoverage = (uuid: any, successCallback = () => {}, failCallback = () => {}) => async dispatch => {
  dispatch({
    type: SET_LOADING_BY_MIDDLE_TYPE,
    loadingData: {
      loading: false,
      modalData: {
        message: "Please wait"
      }
    }
  });
  const { general, saveResidentCoverage } = configs.api.resident;
  const url = `${general}/${uuid}/${saveResidentCoverage}`;
  const method = "POST";
  const mockData = { success: true };
  const response = await fetchApi({ url, method, mockData });

  if (Boolean(response) && response.success === true) {
    successCallback()
  } else {
    failCallback()
  }

  dispatch({
    type: CLEAR_LOADING_BY_MIDDLE_TYPE,
    loadingData: null
  });
};

export const updateResidentProfile = (uuid: any, params, successCallback = () => {}, failCallback = () => {}) => async dispatch => {
  dispatch({
    type: SET_LOADING_BY_MIDDLE_TYPE,
    loadingData: {
      loading: false,
      modalData: {
        message: "Please wait"
      }
    }
  });
  const { general, updateResidentProfile } = configs.api.resident;
  const url = `${general}/${uuid}/${updateResidentProfile}`;
  const mockData = { success: true };


  const {data} = await withAxios.post(getUrl(url), params)

  if (Boolean(data) && data.status === "success") {
    successCallback()
  } else {
    failCallback()
  }

  dispatch({
    type: CLEAR_LOADING_BY_MIDDLE_TYPE,
    loadingData: null
  });
};

export const selfSignUp = (formData, history) => async (dispatch: Dispatch) => {

  dispatch({
    type: SET_LOADING_BY_MIDDLE_TYPE,
    loadingData: {
      loading: true,
      modalData: {
        message: "Please wait"
      }
    }
  });

  const url = configs.api.selfSignUp;
  const response = await fetchApi({
    url,
    param: {
      ...formData,
      sms: false,
      current_resident: null
    },
    mockData: { success: true, resident: { resident } },
    method: "POST"
  });

  if (!response || !response?.success) {
    handleApiFail(dispatch, SELF_SIGN_UP_FAILURE, response, "MESSAGE.SAVE_FAIL", true);
  } else {
    history.push({
      pathname: COVERAGE_ROUTE.path,
      state: {
        startUrl: startUrls.personalAccess,
        prevUrl: COMMUNITY_INFORMATION_ROUTE.path,
        residentData: formData
      }
    })
  }

  if (response?.success) {
    // @ts-ignore
    dispatch(accountSignUp(response.resident.uuid))
  }

  await setStorageObj("resident", {...response, prevPage: configs.api.selfSignUp});

  dispatch({
    type: CLEAR_LOADING_BY_MIDDLE_TYPE,
    loadingData: null
  });

  dispatch({
    type: SELF_SIGN_UP_SUCCESS,
    resident: {...response, prevPage: configs.api.selfSignUp}
  });
};

export const accountSignUp = (uuid: string | undefined) => async (dispatch: Dispatch) => {

  dispatch({
    type: SET_LOADING_BY_MIDDLE_TYPE,
    loadingData: {
      loading: true,
      modalData: {
        message: "Please wait"
      }
    }
  });

  const {data} = await withAxios.post(getUrl(configs.api.accountSignUp), ({uuid}))

  // const response = await fetchApi({
  //   url: configs.api.accountSignUp,
  //   param: {uuid},
  //   mockData: {success: true, resident: {resident}},
  //   method: "POST"
  // });

  dispatch({
    type: CLEAR_LOADING_BY_MIDDLE_TYPE,
    loadingData: null
  });

  return data;
};

export const searchAuthProfile = async (params: {type: 'phone_number'|'email'; value: string}) => {

  const url = configs.api.resident.searchAuthProfile

  const {data} = await withAxios.post(getUrl(url), params)

  return data;
};

export const addAndLinkProfile = async (params: {
  primary_auth_sub?: string,
  type: "phone_number",
  value: string
}) => {

  const url = configs.api.resident.addAndLinkProfile

  const {data} = await withAxios.post(getUrl(url), params)

  return ({status: 'success'})

  return data;
};


