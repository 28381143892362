import React from "react";
import {HeaderSection} from "../../components/primitive/headerSection";
import {IonContent, IonFooter, IonGrid, IonRow, IonText} from "@ionic/react";
import {Wrapper} from "../../../core/styles";
import {makeStyles} from "@material-ui/core/styles";
import {style} from "../../components/primitive/coverageStatusSection/style";
import {colors} from "../../business/constants/global";
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import {UniversalButton} from "../../components/primitive/buttons";
import {useHistory} from "react-router-dom";
import {ACCOUNT_SETTINGS_ROUTE, MY_COVERAGE_ROUTE, PREFERENCES_ROUTE} from "../../business/enums/routes";
import LogoutIcon from '@mui/icons-material/Logout';
import {useAuth0} from "@auth0/auth0-react";

export const UserInformation: React.FC = (): JSX.Element => {

  // @ts-ignore
  const classes = makeStyles(() => style(colors.cyan))();

  const {logout} = useAuth0();

  let history = useHistory();

  const appLogout = () => logout({returnTo: window.location.origin});

  return (
    <IonContent>
      <Wrapper className="app-container ion-padding">

        <IonGrid className="gray-bg-head ion-margin-top">
          <HeaderSection backClick={() => history.push({pathname: MY_COVERAGE_ROUTE.path})} hasBackButton={true} previewSteps={false}/>
        </IonGrid>

        <IonRow style={{marginTop: 50, cursor: 'pointer'}}>
          <IonText
            className="flex ion-float-left ion-align-items-center"
            onClick={() => history.push(ACCOUNT_SETTINGS_ROUTE.path)}
          >
            <PersonIcon style={{marginRight: 20, color: colors.cyan}}/>
            <h2 className={classes.mediumTitle}>Account Settings</h2>
          </IonText>
        </IonRow>

        <IonRow style={{marginTop: 40, cursor: 'pointer'}}>
          <IonText
            className="flex ion-float-left ion-align-items-center"
            onClick={() => history.push(PREFERENCES_ROUTE.path)}
          >
            <SettingsIcon style={{marginRight: 20, color: colors.cyan}}/>
            <h2 className={classes.mediumTitle}>Preferences</h2>
          </IonText>
        </IonRow>

        <IonRow style={{marginTop: 40, cursor: 'pointer'}}>
          <IonText onClick={appLogout} className="flex ion-float-left ion-align-items-center">
            <LogoutIcon style={{marginRight: 20, color: colors.cyan}}/>
            <h2 className={classes.mediumTitle}>Logout</h2>
          </IonText>
        </IonRow>


        <IonFooter style={{marginTop: 200}}>
          <UniversalButton
            onClick={() => history.push({
              pathname: MY_COVERAGE_ROUTE.path,
              // state: {startUrl: startUrls.addressConfirmation}
            })}
            color={colors.white}
            value={'BUTTON.BACK_TO_DASHBOARD'}
          />
        </IonFooter>
      </Wrapper>
    </IonContent>
  );
};
