import { COLOR } from '../../../styles';
import intl from '../../../intl';

const dialog = {
  alert: (params) => {
    const button = params.button || {
      text: intl('BUTTON.OK'),
    };

    const alert = document.createElement('ion-alert');
    alert.header = intl(params.title, params.key);
    alert.subHeader = intl(params.subtitle, params.key);
    alert.message = params.text || intl(params.message, params.key);
    if (params.cssClass) alert.cssClass = params.cssClass;
    alert.buttons = [button];

    const rootEl = document.getElementById('root-app');
    if (rootEl) {
      rootEl.appendChild(alert);
      setTimeout(() => {
        alert.present();
      }, 30);
      return alert;
    }
  },

  confirm: (params) => {
    const alert = document.createElement('ion-alert');
    alert.header = intl(params.title);
    alert.subHeader = intl(params.subtitle);
    alert.message = params.text || intl(params.message, params.key);
    if (params.cssClass) alert.cssClass = params.cssClass;
    alert.buttons = [
      {
        text: intl(params.button2) || intl('BUTTON.CANCEL'),
        cssClass: COLOR.SUCCESS,
        handler: params.onPress2 || (() => {}),
      },
      {
        text: intl(params.button) || intl('BUTTON.OK'),
        handler: params.onPress || (() => {}),
      },
    ];

    const rootEl = document.getElementById('root-app');
    if (rootEl) {
      rootEl.appendChild(alert);
      setTimeout(() => {
        alert.present();
      }, 30);
      return alert;
    }
  },
};

export default dialog;
