import React from "react";
import {HeaderSection} from "../../components/primitive/headerSection";
import {Heading} from "../../components/primitive/heading";
import {UniversalButton} from "../../components/primitive/buttons";
import {IonContent, IonFooter} from "@ionic/react";
import {Wrapper} from "../../../core/styles";

interface InsureInfoProps {
  closeInsureInfo?: any;
}

export const InsureInfo: React.FC<InsureInfoProps> = ({closeInsureInfo}) => (

  <IonContent>
    <Wrapper className='app-container ion-padding'>

      <HeaderSection hasBackButton={true} previewSteps={false} backClick={closeInsureInfo}/>

      <Heading hasSuccessLogo={false} text={'What is my insurance company?'}/>

      <p>
        The company name of your insurance provider should be entered here. This is where you purchased renters insurance
        from, and the name can be found on your policy documents.
      </p>
      <IonFooter>
        <UniversalButton
          value={'BUTTON.CONTINUE'}
          onClick={closeInsureInfo}
          disabled={false}
        />
      </IonFooter>
    </Wrapper>
  </IonContent>
);
