import React, {useState} from "react";
import {UniversalButton} from "../../components/primitive/buttons";
import {useHistory} from "react-router-dom";
import {makeStyles} from '@material-ui/core/styles';
import {Heading} from "../../components/primitive/heading";
import {getDate} from "../../business/helpers/date";
import {capitalize, checkStrings} from "../../business/helpers/stringFunctions";
import {ImageModal} from "../../components/primitive/modals";
import {IonCol, IonFooter, IonGrid, IonImg, IonRow} from "@ionic/react";
import {myStyle} from './styles';
import {colors} from "../../business/constants/global";
import {CHOOSE_INSURANCE_ROUTE} from "../../business/enums/routes";
import {policyRecordTypeTypeValues, policyStatusValues, policyTypeValues} from "../../business/constants/resident";
import {ICON_PDF_BLUE} from "../../images";
import {isExpired} from "./functionHelpers";

interface Props {
  coverageInfo: any;
  setCoverageInfo: any;
}

export const CoverageInformation: React.FC<Props> = ({coverageInfo, setCoverageInfo}) => {

  let history = useHistory();

  const isPrimary = coverageInfo.recordType === policyRecordTypeTypeValues.primary;

  const data = isPrimary ? coverageInfo.resident : coverageInfo.resident?.secondary;
  const isWaiver = data?.coverage_type === policyTypeValues.PDLW;

  const [isOpen, setIsOpen] = useState(false);

  const url = `${process.env.REACT_APP_BASE}resident-app/document/${data?.uuid}?record_type=${data?.record_type}`;

  const isExpiring = isExpired(data)

  const headerColor = isPrimary
    ? isExpiring
      ? colors.red
      : colors.cyan
    : colors.yellow;

  const useStyles = makeStyles(() => ({
    ...myStyle,
    coverageStatusInfo: {...myStyle.coverageStatusInfo, background: headerColor}
  }));
  const classes = useStyles();

  const existingFileType = data?.aws_poi?.slice(data?.aws_poi?.length - 3)
  const existingFileUrl = data?.aws_poi

  const coverageName = isWaiver
    ? 'Waiver Coverage'
    : `${capitalize(data?.insurance_company_selected || '')}`;

  const status = (isWaiver && isPrimary) ? 'Active' : (isExpiring ? 'EXPIRING' : data?.status);

  return (
    <IonGrid>
      <Heading text={coverageName} hasSuccessLogo={false}/>

      <IonRow className={classes.coverageStatusInfo}>
        <IonCol size="12" className="ion-no-padding">
          <p>Status</p>
          <h2>{status}</h2>
        </IonCol>
      </IonRow>

      <IonRow>
        {existingFileType === 'pdf' && !isWaiver && (
          <IonCol>
            <div className={"choose-img-box"}>
              <IonRow className="ion-align-items-center">
                <IonCol size="4">
                  <IonImg class="icon-pdf" src={ICON_PDF_BLUE}/>
                </IonCol>
                <IonCol>
                  <p title={existingFileUrl} style={{cursor: "pointer"}}
                     onClick={() => window.open(url, "_blank")}>{data?.poi_filename}</p>
                </IonCol>
              </IonRow>
            </div>
          </IonCol>
        )}
        {existingFileType !== 'pdf' && !isWaiver && (
          <IonCol>
            <div className={"choose-img-box"}>
              <IonRow className="ion-align-items-center">
                <IonCol size="4">
                  <IonImg class="icon-pdf" src={data?.temp_url}/>
                </IonCol>
                <IonCol className={`upload-picture active`}>
                  <p className="view-picture-btn ion-no-padding" style={{cursor: 'pointer'}}
                     onClick={() => setIsOpen(true)}>{data?.poi_filename}</p>
                </IonCol>
              </IonRow>
            </div>
          </IonCol>
        )}
      </IonRow>

      <IonRow className={classes.coverageStatusInfoContent}>
        <IonCol size="12">
          <p>Insurance Provider</p>
          <h2>{coverageName}</h2>
        </IonCol>
      </IonRow>

      {
        isPrimary && data?.record_type === policyTypeValues.HO4 &&
        <IonRow className={classes.coverageStatusInfoContent}>
          <IonCol size="12">
            <p>Policy Number</p>
            <h2>{data?.policy_number || ''}</h2>
          </IonCol>
        </IonRow>
      }

      <IonRow className={classes.coverageStatusInfoContent}>
        <IonCol size="12">
          <p>Named Insured</p>
          <h2>{`${data?.resident_first_name || ''} ${data?.resident_last_name || ''}`}</h2>
        </IonCol>
      </IonRow>

      <IonRow className={classes.coverageStatusInfoContent}>
        <IonCol size="12">
          <p>Mailing Address</p>
          <h2>{`${data?.named_insured_city || ''} ${data?.named_insured_street_address || ''}`}</h2>
        </IonCol>
      </IonRow>

      <IonRow className={classes.coverageStatusInfoContent}>
        <IonCol size="12">
          <p>Policy Effective Date</p>
          <h2>{getDate(data?.policy_effective_date)}</h2>
        </IonCol>
      </IonRow>

      {!isWaiver &&
        <IonRow className={classes.coverageStatusInfoContent}>
          <IonCol size="12">
            <p>Policy Expiration Date</p>
            <h2>{getDate(data?.policy_expiration_date)}</h2>
          </IonCol>
        </IonRow>}
      {data?.coverage_type === policyTypeValues.HO4 &&
        <IonRow className={classes.coverageStatusInfoContent}>
          <IonCol size="12">
            <p>Liability Coverage Amount</p>
            <h2>${data?.policy_limit_of_liability || 0}</h2>
          </IonCol>
        </IonRow>
      }
      {data?.coverage_type === policyTypeValues.HO4 &&
        <IonRow className={classes.coverageStatusInfoContent}>
          <IonCol size="12">
            <p>Additional Insured / Interested Party</p>
            <h2>{data?.interested_party_name}</h2>
          </IonCol>
        </IonRow>
      }
      {/*{data?.coverage_type === policyTypeValues.HO4 &&*/}
      {/*  <IonRow className={classes.coverageStatusInfoContent}>*/}
      {/*    <IonCol size="12">*/}
      {/*      <p>Additional Insured / Interested Party 2</p>*/}
      {/*      <h2>Lorem Ipsum Dolor</h2>*/}
      {/*    </IonCol>*/}
      {/*  </IonRow>*/}
      {/*}*/}
      {data?.temp_url && <ImageModal fileUrl={data?.temp_url} isOpen={isOpen} setIsOpen={setIsOpen}/>}
      <IonFooter>
        <UniversalButton
          onClick={() => setCoverageInfo({...coverageInfo, isShow: false})}
          value="BUTTON.BACK"
        />
        {!isWaiver && !checkStrings(data?.status, policyStatusValues.approved) &&
          <UniversalButton
            value="BUTTON.REPLACE_DOCUMENT"
            onClick={() => history.push(CHOOSE_INSURANCE_ROUTE.path)}
            color={colors.white}
          />}
      </IonFooter>
    </IonGrid>
  );
};
