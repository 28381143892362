import { InsuranceCompanies } from "../../../models/insuranceCompanies";
export const FETCH_INSURANCE_COMPANIES_SUCCESS = 'FETCH_INSURANCE_COMPANIES_SUCCESS';
export const FETCH_INSURANCE_COMPANIES_FAILURE = 'FETCH_INSURANCE_COMPANIES_FAILURE';
export const SET_INSURANCE_COMPANY = 'SET_INSURANCE_COMPANY';
export const CLEAN_INSURANCE_COMPANIES = 'CLEAN_INSURANCE_COMPANIES';
// Action Types
export interface FetchInsuranceCompaniesSuccessType {
  type: typeof FETCH_INSURANCE_COMPANIES_SUCCESS;
  insuranceCompanies: InsuranceCompanies;
}

export interface FetchInsuranceCompaniesFailureType {
  type: typeof FETCH_INSURANCE_COMPANIES_FAILURE;
  err: string;
}

export interface CleanInsuranceCompaniesType {
  type: typeof CLEAN_INSURANCE_COMPANIES;
}

export interface SetInsuranceCompanyType {
  type: typeof SET_INSURANCE_COMPANY;
  checkedCompany: string
}

export type InsuranceCompaniesReducerType = InsuranceCompanies

export type InsuranceCompaniesActionTypes =
  | FetchInsuranceCompaniesSuccessType
  | FetchInsuranceCompaniesFailureType
  | SetInsuranceCompanyType
  | CleanInsuranceCompaniesType;

export const INSURANCE_COMPANIES_INIT_STATE = []
