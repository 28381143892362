import React from "react";
import {FormView} from "../../forms/createPasswordForm";
import {Text} from "../../../../core/components/primitives";
import {IonCol, IonImg, IonRow} from "@ionic/react";
import {ICON_OK} from "../../../images";
import {useHistory} from "react-router-dom";
import {startUrls} from "../../../business/constants/startUrls";

interface PasswordFormModel {
  resident?: any;
}
export const PasswordForm: React.FC<PasswordFormModel> = ({ resident }) => {

  let history: any = useHistory()

  let {state}: any = history.location

  return (
    resident && (
      <>
        {state.startUrl === startUrls.addressConfirmation &&
        <IonRow className="ion-justify-content-center">
          <IonCol size='9' className="ion-padding-top">
            <h3 className="ion-text-center">
              <Text k={ "TEXT.CREATE_YOUR_PASSWORD" } />
            </h3>
          </IonCol>
        </IonRow>}
        <IonRow>
          <IonCol className="ion-padding-top ion-padding-bottom">
            <div className="create-password-email ion-align-items-center">
              <div>
                <IonImg class="icon-ok" src={ICON_OK} />
              </div>
              <div>
                <p>{ resident.email }</p>
              </div>
            </div>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <FormView />
          </IonCol>
        </IonRow>

      </>
    )
  )
};
