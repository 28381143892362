import * as i18n from 'react-intl-universal';
import { Text } from '../components/primitives';

const checkKeyFormat = (key) => {
  if (!key) return false;
  if (key.includes(' ') || key !== key.toUpperCase()) return false;
  return true;
};

const intl = (key, param: any = undefined) => {
  if (!checkKeyFormat(key)) return key;


  if (param) return i18n.get(key || 'EMPTY', param) || key;

  return i18n.get(key || 'EMPTY') || key;
};

export { Text };

export default intl;
