import UserModel from '../../models/user';

import { SET_CUSER, CLEAN_CUSER, CUSER_INIT_STATE } from './types';
import { LOGIN_SUCCESS } from '../auth/types';

export default function currentUserReducer(state: UserModel = CUSER_INIT_STATE, action): UserModel {
  switch (action.type) {
    case SET_CUSER:
      document.body.classList.add('access_' + (action?.user?.userLevel || ''));
      return action.user;
    case CLEAN_CUSER:
      document.body.classList.remove('access_' + (action?.user?.userLevel || ''));
      return CUSER_INIT_STATE;
    default:
      return state;
  }
}
