import {fetchApi, handleApiFail} from "../../../../../core/business/services/api";
import {configs} from "../../../../../configs";
import {FETCH_INSURANCE_COMPANIES_FAILURE, FETCH_INSURANCE_COMPANIES_SUCCESS} from "./types";
import {CLEAR_LOADING_BY_MIDDLE_TYPE, SET_LOADING_BY_MIDDLE_TYPE} from "../loadingByType/types";
import insuranceCompany from "../../../mocks/insuranceCompany.json";

export const fetchInsuranceCompanies = () => async dispatch => {

  dispatch({
    type: SET_LOADING_BY_MIDDLE_TYPE,
    loadingData: {
      loading: true,
      modalData: {
        message: "Please wait"
      }
    }
  });

  const response = await fetchApi({
    url: configs.api.insuranceCompany.general,
    method: "GET",
    mockData: { success: true, companies: insuranceCompany }
  });

  if (!response || !response?.success) {
    return handleApiFail(dispatch, FETCH_INSURANCE_COMPANIES_FAILURE, response, "ERROR.SERVER", true);
  }

  dispatch({
    type: CLEAR_LOADING_BY_MIDDLE_TYPE,
    loadingData: null
  });

  dispatch({
    type: FETCH_INSURANCE_COMPANIES_SUCCESS,
    insuranceCompanies: response
  });
};
