import { configs } from '../../../../configs';

export const INIT_SUCCESS = 'INIT_SUCCESS';
export const INIT_FAILURE = 'INIT_FAILURE';
export const LOAD_HEADERS = 'LOAD_HEADERS';

// Action Types
export interface InitSuccessType {
  type: typeof INIT_SUCCESS;
}

export interface InitFailureType {
  type: typeof INIT_FAILURE;
  err: string;
}
export interface LoadHeadersType {
  type: typeof LOAD_HEADERS;
}

export interface InitModel {
  initSuccess: boolean;
  headers: object;
  public?: {
    resetRequested?: boolean;
    err?: string | undefined;
  };
}

export const INIT_STATE: InitModel = {
  initSuccess: false,
  public: undefined,
  headers: {},
};
