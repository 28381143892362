import React from "react";
import {AppOutlet} from "../../../_init/appOutlet";
import {Auth0ProviderWithHistory} from "../../hoc/Auth0ProviderWithHistory";
import {IonReactRouter} from "@ionic/react-router";
import {IonApp} from "@ionic/react"

export const NormalLayout: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
      {/*<IonReactRouter basename={window.location.origin}>*/}
        <Auth0ProviderWithHistory>
          <AppOutlet />
        </Auth0ProviderWithHistory>
      </IonReactRouter>
    </IonApp>
  );
};
