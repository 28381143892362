export const apartmentStyle = {
root: {
    '& input': {
      height: '56px',

    },
    '& ion-select-popover': {
      width: '10px!important'
    },
    width: '100%',
    '& > *': {
      margin: '2px',
      width: '100%',
    },
  },
  inputGroup: {
    marginBottom: 22,
    '& ion-label': {
      color: '#2D2D2D',
      fontSize: 16,
    },
    '& .ion-border':{
      marginTop: 10,
      fontSize: 16,
    },
    '& ion-select':{
      marginTop: 10,
    }
  },
  headingWithIcon: {
    alignItems: 'center',
  },
  headingIcon: {
    maxWidth: 44,
  },
  headingText: {
    margin: 0,
    color: '#2D2D2D',
    letterSpacing: '0.2px',
    fontFamily: 'InfraMedium',
    fontSize: 22
   }
}
