import { User } from "@auth0/auth0-react";


export default interface UserModel extends User{
  userId?: number;
  firstName: string;
  lastName: string;
  email: string;
  userLevel?: number;
  other?: any;
  settings?: any;
}

export const emptyUser: UserModel = {
  userId: 0,
  firstName: '',
  lastName: '',
  email: '',
};

export const defaultUser = {
  userId: 0,
  firstName: '',
  lastName: '',
  email: '',
  userLevel: 1,
};

export const getFullName = user => {
  if (!user) return '';
  return `${user?.given_name || ''} ${user?.family_name || ''}`;
};

export const getMobile = user => {
  if (user?.other) return '';
  return user?.other?.mobile || '';
};

export const deriveRawToUser = raw => {
  if (!raw) return defaultUser;

  const { firstName, lastName, email, level, userId, id, phone, mobile, token, other, settings } = raw;

  return {
    userId: userId || id,
    firstName,
    lastName,
    email,
    other: {
      ...other,
      mobile: phone || mobile || '',
      token: token || '',
    },
    ...(settings && { settings }),
  };
};
