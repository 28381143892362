import appConfigs from '../product/config/configs.json';
import appFeatures from '../product/config/features.json';
import coreConfigs from '../core/config/configs.json';
import coreFeatures from '../core/config/features.json';
import * as domain from "domain";

const configs = {
  ...coreConfigs,
  ...appConfigs,
  api: {
    ...coreConfigs.api,
    ...appConfigs.api,
  },
  localStorage: [...coreConfigs.localStorage, ...appConfigs.localStorage],
  display: {
    ...coreConfigs.display,
    ...appConfigs.display,
  },
};

const features = {
  ...coreFeatures,
  ...appFeatures,
};
const auth0Configs = {
  "domain": `${process.env.REACT_APP_AUTH0_DOMAIN}`,
  "clientId": `${process.env.REACT_APP_AUTH0_CLIENT_ID}`,
  "audience": `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
}
export { configs, features, auth0Configs };
