import {COVERAGE_ROUTE, POLICY_INFORMATION_SENT_ROUTE} from "../../business/enums/routes";
import {ICON_DONE, ICON_USER} from "../../images";
import {startUrls} from "../../business/constants/startUrls";

export function getHeaderText(state: {prevUrl: string; startUrl: string}) {
  if (state.prevUrl === POLICY_INFORMATION_SENT_ROUTE.path) {
    return 'Please create a user account to track your renters insurance coverage.'
  } else if (state.prevUrl === COVERAGE_ROUTE.path && state.startUrl === startUrls.personalAccess) {
    return 'Please create a user account to manage your coverage'
  } else {
    return 'Thank you, we saved your information!'
  }
}

export const getIcon = state => state.startUrl === startUrls.personalAccess ? ICON_USER : ICON_DONE
