import {
  UnitsByPrIdActionTypes,
  UnitsByPrIdReducerType,
  UNITS_BY_PR_ID_INIT_STATE,
  FETCH_UNITS_BY_PR_ID_SUCCESS,
  FETCH_UNITS_BY_PR_ID_FAILURE,
} from './types';

export const unitsReducer = (
  state: UnitsByPrIdReducerType = UNITS_BY_PR_ID_INIT_STATE,
  action: UnitsByPrIdActionTypes,
): UnitsByPrIdReducerType => {
  switch (action.type) {
    case FETCH_UNITS_BY_PR_ID_SUCCESS:
      return action.units
    case FETCH_UNITS_BY_PR_ID_FAILURE:
      return UNITS_BY_PR_ID_INIT_STATE
    default:
      return state;
  }
}
