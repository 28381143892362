import React from "react";
import { StyledIonImage, Wrapper } from "./styles";

interface Props {
  className?: string;
  icon: any;
  text: string;
}
export const Introduction: React.FC<Props> = ({ className = "", icon, text }) => (
  <Wrapper className={`${className}`}>
    <StyledIonImage class="logo waiver-popup-img" src={icon} />
    <h3  className="title-18 ion-text-uppercase ion-text-center">{text}</h3>
  </Wrapper>
);
