import React from "react";
import {Text} from "../../../../core/components/primitives";
import {Wrapper} from "../../../../core/styles";
import {IonContent, IonFab} from "@ionic/react";

interface Props {
  loadingText?: string
}

export const Spinner: React.FC<Props> = ({loadingText = "PROGRESS.LOADING"}) => (
  <IonContent>
    <Wrapper className="app-container ion-padding">
      <IonFab vertical="center" horizontal="center" slot="fixed">
        <div className="ion-text-center">
          <svg version="1.1" viewBox="0 0 64 64" width="70" xmlns="http://www.w3.org/2000/svg"
               id="spinner">
            <circle className="path-gradient" cx="32" cy="32" r="28" fill="none" stroke="url(#sGD)"
                    strokeWidth="4"/>
            <path className="path-solid" d="M 32,4 A 28 28,0,0,0,32,60" fill="none" stroke="#43CED3"
                  strokeWidth="4" strokeLinecap="round"/>
            <defs>
              <linearGradient id="sGD" gradientUnits="userSpaceOnUse" x1="32" y1="0" x2="32" y2="64">
                <stop stopColor="#43CED3" stopOpacity="0" offset="0%"/>
                <stop stopColor="#43CED3" stopOpacity=".6" offset="60%"/>
                <stop stopColor="#43CED3" offset="100%"/>
              </linearGradient>
            </defs>
            <animateTransform
              values="0,0,0;360,0,0"
              attributeName="transform"
              type="rotate"
              repeatCount="indefinite"
              dur="1s">
            </animateTransform>
          </svg>
          <p><Text k={loadingText}/></p>
        </div>
      </IonFab>
    </Wrapper>
  </IonContent>
);
