import {fetchApi} from "../../../core/business/services/api";
import {sleep} from "../../../core/business/helpers/util";
import {values} from "lodash";
import {validateEmail, validatePhone} from "../../../core/business/helpers/input";

export const checkHasEmail = async (email: string) => {
  const url = `resident-app/check-email/${email}`
  const method = 'GET'
  const mockData = {
    success: true,
    exists: true
  }
  return await fetchApi({ url, method, mockData })
}

export const checkUserVerified = async (param: { type: string; value: string }) => {
  const url = `resident-app/check-user-verified`
  const method = "POST";
  const mockData = {
    success: true,
    exists: Boolean((Math.random()>=0.5)? 1 : 0)
  }
  await sleep(1000);
  return mockData;
  // return await fetchApi({ url, method, mockData, param })
}

export const getValidText = (type: string, value: string) => {
  if (!value) return '* This field is required'

  if (type === 'phone_number' && !validatePhone(value)) {
    return '* Invalid phone number format'
  } else if (type === 'email' && !validateEmail(value)) {
    return '* Invalid email address format';
  } else return ""
}
