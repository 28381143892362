import UserModel, { defaultUser } from '../..//models/user';

export const SET_CUSER = 'SET_CUSER_SUCCESS';
export const CUSTOMER_FAILURE = 'CUSTOMER_FAILURE';
export const CLEAN_CUSER = 'CLEAN_CUSER';

// Action Types
export interface FailureType {
  type: typeof CUSTOMER_FAILURE;
  err: string;
}
export interface SetCuserType {
  type: typeof SET_CUSER;
  user: UserModel;
}
export interface CleanCuserType {
  type: typeof CLEAN_CUSER;
}

export type CuserActionTypes = SetCuserType | CleanCuserType | FailureType;

// Reducer Model
export const CUSER_INIT_STATE = defaultUser;
