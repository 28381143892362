import styled from 'styled-components';
import { FONT, Mobile, SPACE, WEIGHT } from '../../../styles';

const common = (props) => `
  .MuiTableCell-root,
  .MuiTableCell-head
  {
    font-weight: normal;
    line-height: ${FONT.MEDIUM};
    border-bottom: none;
    padding: ${props.cellPadding || 'inherit'};
    display: flex;
    align-items: center;
    justity-content: start;
  }
  .MuiTableSortLabel-root:hover {
    color: var(--ion-color-light);
    ${props.headerColor ? `color: var(--ion-color-${props.headerColor}-contrast-bw);` : ''};
  }
  .MuiTableSortLabel-root.MuiTableSortLabel-active,
  .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active
    .MuiTableSortLabel-icon {
    color: var(--ion-color-light);
    ${props.headerColor ? `color: var(--ion-color-${props.headerColor}-contrast-bw);` : ''};
    font-weight: ${WEIGHT.BOLD};
  }

  .grouped-row {
    background-color: var(--ion-color-${props.groupColor});
    padding: 0;
    .grouped-cell {
      padding: ${SPACE.MEDIUM};
      color: var(--ion-color-${props.groupColor}-contrast-bw);
      min-height: initial;
    }
  }
`;

export const Vwrapper = styled.div`
  height: calc(100% - ${(p) => p.additionalHeight || 0}px);

  ${(p) => common(p)}
  .MuiCheckbox-root {
    padding: 0;
  }
  .MuiTableCell-root {
    border-bottom: none;
    padding: ${(p) => p.cellPadding || SPACE.MEDIUM};
    overflow-wrap: break-word;
    ${(p) => (p.rowHeight ? `min-height: ${p.rowHeight}px;` : '')}
  }
  .MuiTableCell-root.vertical {
    flex-direction: column;
    line-height: normal;
    justify-content: center;
    &.left {
      align-items: flex-start;
    }
    &.right {
      align-items: flex-end;
    }
  }

  .MuiTableCell-root.nowrap {
    overflow-wrap: normal;
  }
  .MuiTableCell-root {
    overflow: hidden;
    ${(p) => (p.fontColor ? `color: ${p.fontColor}` : '')};
    ${(p) => (p.fontSize ? `font-size: ${p.fontSize}` : '')};
  }
  .ReactVirtualized__Table__headerRow,
  .MuiTableRow-head,
  .MuiTableCell-head {
    background: ${(p) => `var(--ion-color-${p.headerColor || 'primary'})`};
    color: ${(p) => `var(--ion-color-${p.headerColor || 'primary'}-contrast-bw)`};
  }
  /* .MuiTableRow-root .MuiTableCell-root:first-child {
    padding-left: calc(${(p) => p.cellPadding || SPACE.MEDIUM} + 2px);
  } */
  .ReactVirtualized__Table__headerColumn,
  .MuiTableRow-head {
    ${(p) => (p.headerHeight ? `height: ${p.headerHeight}px` : '')};
    overflow: hidden;
  }
  .MuiTableRow-root {
    ${(p) => p.borderColor && `border-bottom: 1px solid ${p.borderColor};`}
  }

  .ReactVirtualized__Table__Grid {
    min-width: 100% !important;
  }
  .ReactVirtualized__Grid__innerScrollContainer {
    max-width: 100% !important;
  }

  .virtualized-table {
    ${(p) => (p.minWidth ? `min-width: ${p.minWidth}px;` : '')}
    ${(p) => (p.maxWidth ? `max-width: ${p.maxWidth}px;` : '')}
  }
  .virtualized-row {
    display: flex;
    ${(p) => (p.minWidth ? `min-width: ${p.minWidth}px;` : '')}
    ${(p) => (p.maxWidth ? `max-width: ${p.maxWidth}px;` : '')}
  }
  .select-all {
    color: ${(p) => `var(--ion-color-${p.headerColor || 'primary'}-contrast-bw)`};
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: inherit;
  }

  .pagination {
    ${(p) => (p.maxWidth ? `max-width: ${p.maxWidth}px;` : '')}
  }
  .MuiPagination-root {
    display: flex;
    justify-content: center;
  }

  ${(p) =>
    p.stripped
      ? `
        .virtualized-row:nth-child(even) {
        background: rgba(var(--ion-color-light-contrast-rgb), 0.05);
        }`
      : ''}

  .inner-table {
    justify-content: flex-start;
    padding: 8px 24px;
  }
`;

export const MobileWrapper = styled(Mobile)`
  margin-left: -${SPACE.LARGE};
  margin-right: -${SPACE.LARGE};

  ${(p) => common(p)}

  ul.list li {
    padding-left: ${SPACE.MEDIUM};
    padding-right: 0;
    border-bottom: 1px solid var(--ion-border-color);

    .center {
      text-align: initial !important;
    }
    .label {
      font-size: 0.9em;
    }
    .value {
      font-weight: ${WEIGHT.SEMI_BOLD};
    }
    .MuiCheckbox-root {
      padding-right: 0;
      padding-left: ${SPACE.MEDIUM};
    }
    .image {
      padding-left: ${SPACE.SMALL};
      max-height: 64px;
      max-width: 64px;
    }
    div.text {
      flex: 1;
      text-align: left;
      padding-left: ${SPACE.MEDIUM};
    }
    .main {
      font-size: 1.1em;
      font-weight: ${WEIGHT.SEMI_BOLD};
    }
    .action {
      margin-right: ${SPACE.MEDIUM};
      display: flex;
      flex-direction: column;
      button {
        min-width: 32px;
      }
    }
  }
`;
