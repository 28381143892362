import React from 'react';
import {IonText} from "@ionic/react";
import DehazeIcon from '@mui/icons-material/Dehaze';
import {useHistory} from "react-router-dom";
import {ACCOUNT_SETTINGS_ROUTE, USER_INFORMATION_ROUTE} from "../../../../business/enums/routes";

interface Props {
}

export const MenuDropdownButton: React.FC<Props> = () => {

  let history = useHistory();

  const clickHandler = () => history.push({
    pathname: USER_INFORMATION_ROUTE.path,
    state: history.location.state
  })

  return (
    <IonText
      className="header-logout-btn"
      onClick={clickHandler}
    >
      <DehazeIcon/>
    </IonText>
  )
}
