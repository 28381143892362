import { FONT, SPACE } from '../../../styles';
import { desktopStyle } from '../../../styles/utils';
import { IonModal } from '@ionic/react';
import { Dialog } from '@mui/material';
import Close from '@mui/icons-material/Close';
import styled from 'styled-components';

export const ModalWrapper = styled(IonModal)`
  ${(p) => p.minWidth && `--min-width: ${p.minWidth};`}
  ${(p) => p.width && `--width: ${p.width};`}
  ${(p) => p.minHeight && `--min-height: ${p.minHeight};`}
  ${(p) => p.height && `--height: ${p.height};`}
  ${(p) => p.borderRadius && `--border-radius: ${p.borderRadius};`}

  .cancel-button {
    font-size: 30px;
    background: white;
    border-radius: 50%;
  }
`;

export const DialogModal = styled(Dialog)`
  .close-button {
    position: absolute;
    right: 0;
  }

  .MuiBackdrop-root.loading-backdrop {
    position: absolute;
    z-index: 200;
    flex-direction: column;

    .loading-text {
      color: #fff;
      font-size: ${FONT.MEDIUM};
      margin-top: ${SPACE.MEDIUM};
    }
    .MuiCircularProgress-root {
      color: #fff;
    }
  }

  ${(p) =>
    desktopStyle(`
    .MuiDialog-paperWidthSm {
      ${p.minwidth ? `min-width: ${p.minwidth};` : ''}
      ${p.width ? `max-width: ${p.width};` : ''}
      ${p.maxHeight ? `max-height: ${p.maxHeight};` : ''}
      position: relative;
    }
  `)}
`;

export const CancelButton = styled(Close)`
  position: absolute;
  right: 4px;
  top: 4px;
  z-index: 1;
  font-size: 24px !important;
`;
