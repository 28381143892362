import { dialog } from '../../components/reusables';
import intl from '../../intl';
import { SORT } from '../enums';
import { AMEX, DISCOVER, MASTERCARD, VISA } from '../enums/cardTypes';
import { ASC, DSC } from '../enums/sort';
import { SideMenuModel } from '../models/sideMenu';
import { getStorageObj } from '../services/local.storage';

export const clog = (...items) => {
  if (process.env.REACT_APP_ENV !== 'prod') {
    console.log(...items);
  }
};

export const itemText = (action: string, item: string) => {
  return intl('ITEM.' + action.toUpperCase(), { item: intl('COMMON.' + item.toUpperCase()) });
};

export const leadingZero = (number, digits) => {
  const zeros = Array(digits).fill(0).join('');
  return (zeros + number).slice(-4);
};

export const accessString = (accessLevel, feature = '') => {
  const accessClass =
    accessLevel === undefined || accessLevel === null ? '' : `access_${accessLevel.toString()}`;
  const featureClass = feature ? `feature_${feature}` : '';
  return `${accessClass} ${featureClass}`;
};

export const canAccess = (accessLevel, userLevel) => {
  if (accessLevel === undefined || accessLevel === null) return true;
  if (accessLevel === 0 || accessLevel) {
    if (userLevel <= accessLevel) return true;
  }
  return false;
};

export const hasFeature = async (feature) => {
  const localFeatures = await getStorageObj('features');
  // const appFeature = document.body.classList.contains(`body_${feature}`);
  if (localFeatures[feature]) return true;
  return false;
};

export const toFileSize = (number) => {
  if (isNaN(number) || number == null) return '';
  if (number >= 1000000000000) return `${(number / 100000000000).toFixed(0)} TB`;
  if (number >= 1000000000) return `${(number / 100000000).toFixed(0)} GB`;
  if (number >= 1000000) return `${(number / 1000000).toFixed(0)} MB`;
  if (number >= 1000) return `${(number / 1000).toFixed(0)} KB`;
  return `${number} B`;
};

export const sleep = (m) => new Promise((r) => setTimeout(r, m));

export const changeSort = (order) => (order === DSC ? ASC : DSC);

export const intToArray = (number) =>
  Array(number)
    .fill(1)
    .map((v, i) => i);

export const flattenObject = (obj) => {
  return Object.assign(
    {},
    ...(function _flatten(o) {
      return [].concat(
        ...Object.keys(o).map((k) => (typeof o[k] === 'object' ? _flatten(o[k]) : { [k]: o[k] })),
      );
    })(obj),
  );
};

export const popPlaceholder = (text = '') => {
  dialog.alert({
    title: 'MESSAGE.SORRY',
    message: 'MESSAGE.FEATURE_NOT_READY',
    ...(text && { text }),
    key: { name: 'Supercalifrgerlisticexplialidocous' },
  });
};

export const sortArray = (data, key, order) => {
  const temp = [...data];
  temp.sort((a, b) =>
    order === SORT.ASC
      ? a[key] > b[key]
        ? 1
        : b[key] > a[key]
        ? -1
        : 0
      : a[key] < b[key]
      ? 1
      : b[key] < a[key]
      ? -1
      : 0,
  );
  return temp;
};

export const mirrorObjKeys = (obj) => {
  const newObj = {};
  for (let i in obj) {
    newObj[i] = i;
  }
  return newObj;
};

export const getStyleColor = (color, colorType = '') => {
  return `var(--ion-color-${color}${colorType ? `-${colorType}` : ''})`;
};

export const addMinutesToTimestamp = (seconds, timestamp = 0) => {
  const now = timestamp || +new Date();

  return now + seconds * 1000;
};

export const getLast4 = (str) => {
  if (!str) return '';
  if (str.length > 4) return str.toString().substr(-4);
  return str;
};

export const getCardType = (str) => {
  if (!str) return '';
  switch (str[0] || '') {
    case '3':
      return AMEX;
    case '4':
      return VISA;
    case '5':
      return MASTERCARD;
    case '6':
      return DISCOVER;
    default:
      return '';
  }
};

export const randomInt = (min, max) => {
  return parseInt(Math.floor(Math.random() * (max - min + 1)) + min);
};

export const percentage = (amount, decimal = 1) => Number(amount).toFixed(decimal) + '%';

export const currency = (amount) => intl('PRICE', { amount });

export const dollar = (amount, useDecimal = true) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    ...(!useDecimal && { maximumFractionDigits: 0 }),
  });

  return formatter.format(amount);
};

export const getCardPathFromType = (cardType) => {
  return `/assets/img/${cardType.toLowerCase()}_icon.png`;
};

export const getDefaultCardId = (cards) => {
  const card = cards.find((c) => c.isDefault);
  if (!card) return 0;
  return card.cardId;
};

export const getCardPath = (cardNumber) => {
  let fileName;
  switch (cardNumber[0] || '') {
    case '3':
      fileName = 'amex';
      break;
    case '4':
      fileName = 'visa';
      break;
    case '5':
      fileName = 'mastercard';
      break;
    case '6':
      fileName = 'discover';
      break;
    default:
      fileName = 'card';
      break;
  }
  return `/assets/img/${fileName}_icon.png`;
};

export const parseAddress = (add) => {
  if (!add) return '';

  const tmp = add.split(',');
  let street;
  if (tmp.length <= 1) return add;

  street = tmp[0];
  if (tmp.length === 2) {
    return {
      street,
      zip: tmp[1],
    };
  }
  if (tmp.length > 2) {
    const tmp2 = tmp[2].trim().split(' ');
    return {
      street,
      city: tmp[1],
      state: tmp2[0],
      zip: tmp2[1],
    };
  }

  return add;
};

export const joinAddress = (address) => {
  const { city, state, zip } = address;
  if (city && state) return `${city}, ${state} ${zip}`;
  return zip;
};

export const formatAddress = (address) => {
  if (!address) return address;
  return address.replace(',', '<br>');
};

export const replaceAll = (str, mapObj) => {
  var re = new RegExp(Object.keys(mapObj).join('|'), 'gi');

  return str.replace(re, function (matched) {
    return mapObj[matched.toLowerCase()];
  });
};

export const getSideMenu = (route): SideMenuModel => {
  return {
    title: route.title,
    ...(route.icon && { icon: route.icon }),
    ...(isUrl(route.path)
      ? { href: route.path }
      : route.path && {
          path: `${route.path}${route.defaultId ? '/' + route.defaultId : ''}`,
        }),
    ...(route.component && { route: route }),
    ...(route.routes && {
      submenu: route.routes.map((r) => getSideMenu(r)),
    }),
  };
};

export const isUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};
export const generateGetParam = (initialParam) => {
  const param = {
    ...(!initialParam ? {} : initialParam),
  };
  return new URLSearchParams(param).toString();
};
