import {
  InsuranceCompaniesActionTypes,
  InsuranceCompaniesReducerType,
  INSURANCE_COMPANIES_INIT_STATE,
  FETCH_INSURANCE_COMPANIES_SUCCESS,
  FETCH_INSURANCE_COMPANIES_FAILURE,
  CLEAN_INSURANCE_COMPANIES,
} from "./types";

export default function insuranceCompaniesReducer(
  state: InsuranceCompaniesReducerType = INSURANCE_COMPANIES_INIT_STATE,
  action: InsuranceCompaniesActionTypes,
): InsuranceCompaniesReducerType {
  switch (action.type) {
    case FETCH_INSURANCE_COMPANIES_SUCCESS:
      return action.insuranceCompanies
    case FETCH_INSURANCE_COMPANIES_FAILURE:
      return INSURANCE_COMPANIES_INIT_STATE;
    case CLEAN_INSURANCE_COMPANIES:
      return INSURANCE_COMPANIES_INIT_STATE;
    default:
      return state;
  }
}
