import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {PasswordForm} from "../../components/primitive/passwordForm";
import {uploadPolicy} from "../../business/redux/reducers/policyDocument/actions";
import {SelectInsuranceData} from "../selectInsuranceData";
import {fetchResident, syncLocalResident} from "../../business/redux/reducers/resident/actions";
import {PolicyInformationSent} from "../policyInformationSent";
import {HeaderSection} from "../../components/primitive/headerSection";
import {InsureInfo} from "./insureInfo";
import {FileInfo} from "./fileInfo";
import {StoreModel} from "../../business/models/store";
import {IonContent, IonGrid, IonRow} from "@ionic/react";
import {Wrapper} from "../../../core/styles";
import {configs} from "../../../configs";

export const ChooseInsurance: React.FC = (): JSX.Element => {

  const [insureInfo, setInsureInfo] = useState(false);
  const [fileInfo, setFileInfo] = useState(false);

  const dispatch = useDispatch();
  const [step, setStep] = useState(1)
  const {resident} = useSelector((state: StoreModel) => state)

  useEffect(() => {
    dispatch<any>(syncLocalResident())
    if (resident && resident.success && resident.resident?.secondary && !resident.resident.auth0_sub) {
      setStep(3)
    }
  }, [resident?.resident?.uuid])
  useEffect(() => {
    if (resident?.resident?.uuid && (resident?.prevPage && resident?.prevPage !== configs.api.selfSignUp)) {
      dispatch<any>(fetchResident({id: resident.resident.uuid}));
    }
  }, [resident?.resident?.uuid])
  const [selectedInsuranceCompany, setSelectedInsuranceCompany] = useState({key: "", value: "", text: ""})
  const [fileUrl, setFileUrl] = useState("")
  const [otherCompany, setOtherCompany] = useState("")

  const stepNames = ['PERSONAL_INFORMATION', 'POLICY_INFORMATION', 'LOG_IN'];

  const openInsureInfo = () => setInsureInfo(true)
  const closeInsureInfo = () => setInsureInfo(false)

  const openFileInfo = () => setFileInfo(true)
  const closeFileInfo = () => setFileInfo(false)

  return (
    <IonContent>
      {insureInfo && <InsureInfo closeInsureInfo={closeInsureInfo}/>}
      {fileInfo && <FileInfo closeFileInfo={closeFileInfo}/>}
      {
        resident && resident.success && resident.resident && !insureInfo && !fileInfo && (
          <Wrapper className="app-container ion-padding">

            <HeaderSection
              activeStep={2}
              stepNames={stepNames}
              hasBackButton={true}
            />

            <IonRow>
              <IonGrid>
                {
                  (step < 4) &&
                  <SelectInsuranceData
                    step={step}
                    setStep={setStep}
                    selectedInsuranceCompany={selectedInsuranceCompany}
                    setSelectedInsuranceCompany={setSelectedInsuranceCompany}
                    uploadPolicy={uploadPolicy}
                    otherCompany={otherCompany}
                    setOtherCompany={setOtherCompany}
                    fileUrl={fileUrl}
                    setFileUrl={setFileUrl}
                    resident={resident.resident}
                    recordType={resident?.resident?.record_type}
                    openInsureInfo={openInsureInfo}
                    openFileInfo={openFileInfo}
                  />
                }
                {
                  (step === 4) &&
                  <PolicyInformationSent/>
                }
                {
                  (step === 5) &&
                  <PasswordForm
                    resident={resident.resident}
                  />
                }
              </IonGrid>
            </IonRow>
          </Wrapper>
        )
      }
    </IonContent>
  );
};
