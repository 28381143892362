import styled from 'styled-components';
import { SPACE, ENUMS } from './enums';
import { Div } from './index';
import { fullScreen } from './mixins';
import { IonFooter } from '@ionic/react';

export const Wrapper = styled(Div)`
  padding: ${props => props.padding || SPACE.LARGE};
  max-width: ${props => props.maxWidth || 'none'};
  flex: 1;

  @media screen and (max-width: ${ENUMS.MOBILE_WIDTH}) {
    padding: ${props => props.padding || SPACE.LARGE};
  }
`;

export const PageWrapper = styled(Div)`
  padding: ${SPACE.LARGE};
`;

export const WrapperDesktop = styled(Div)`
  @media screen and (min-width: ${ENUMS.DESKTOP_MIN_WIDTH}) {
    padding: ${props => props.padding || SPACE.LARGE};
    max-width: ${props => props.maxWidth || 'none'};
  }
`;

export const ItemWrapper = styled(Div)`
  margin-left: -${props => props.padding || SPACE.LARGE};
  margin-right: -${props => props.padding || SPACE.LARGE};

  @media screen and (max-width: ${ENUMS.MOBILE_WIDTH}) {
    margin-left: -${props => props.padding || SPACE.LARGE};
    margin-right: -${props => props.padding || SPACE.LARGE};
  }
`;

export const Flex = styled(Div)`
  display: flex;
  align-items: center;
`;

export const FlexY = styled(Div)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FlexWrapper = styled(Div)`
  display: flex;
  ${fullScreen}
  overflow: hidden;
  flex-direction: column;
`;

export const ListContainer = styled(Div)`
  &&.virtualized {
    overflow: hidden;
    height: 100%;
  }
`;

export const Footer = styled(IonFooter)`
  ${p => (p.border ? '' : '&:before { background-image: none; }')}
  height: ${p => p.height || 'auto'};
`;

export const Mobile = styled(Div)`
  display: none;

  @media screen and (max-width: ${ENUMS.MOBILE_WIDTH}) {
    display: block;
  }
`;

export const MobileOver = styled(Div)`
  display: inherit;

  @media screen and (max-width: ${ENUMS.MOBILE_WIDTH}) {
    display: none;
  }
`;

export const Split = styled(Div)`
  display: none;

  @media screen and (max-width: ${ENUMS.SPLIT_PANE_WIDTH}) {
    display: block;
  }
`;

export const SplitOver = styled(Div)`
  display: inherit;

  @media screen and (max-width: ${ENUMS.SPLIT_PANE_WIDTH}) {
    display: none;
  }
`;

export const Desktop = styled(Div)`
  display: inherit;

  @media screen and (max-width: ${ENUMS.DESKTOP_MIN_WIDTH}) {
    display: none;
  }
`;
