import React from 'react';
import {IonText} from "@ionic/react";
import {policyRecordTypeTypeValues, policyStatusValues, policyTypeValues} from "../../../business/constants/resident";
import {makeStyles} from '@material-ui/core/styles';
import {style} from "./style";
import {colors} from "../../../business/constants/global";
import {StatusSection} from "./statusSection";
import {WaiverStatusSection} from "./waiverStatusSection";
import {UniversalButton} from "../buttons";
import {Ho4StatusSection} from "./ho4StatusSection";
import {useMediaQuery} from "@mui/material";
import {ENUMS} from "../../../../core/styles";

interface Props {
  resident: any;
  sectionType?: 'ACTIVE' | 'PENDING_REVIEW' | 'EXPIRING';
  onClick?: any;
  setIsOpen?: any;
}

export const CoverageStatusSection: React.FC<Props> = ({
                                                         resident,
                                                         sectionType = 'ACTIVE',
                                                         onClick = () => {},
                                                         setIsOpen = () => {},
                                                       }) => {

  const isMobile = useMediaQuery(`(max-width:${ENUMS.MOBILE_WIDTH})`);

  const color = sectionType === 'ACTIVE'
    ? colors.cyan
    : sectionType === 'EXPIRING'
      ? colors.red
      : colors.yellow

  // @ts-ignore
  const useStyles = makeStyles(() => style(color, isMobile));
  const classes = useStyles();

  const isWaiver = resident?.coverage_type === policyTypeValues.PDLW;

  const isCurrent = resident?.record_type === policyRecordTypeTypeValues.primary

  return (
    <StatusSection color={color} resident={resident}>
      <IonText className="ion-text-center">
        <p className={classes.paragraph}>
          {isCurrent
            ? 'Current coverage'
            : resident?.status === policyStatusValues.approvedNotActiveYet
              ? 'This coverage will begin on the policy effective date'
              : 'Under review'}
        </p>
      </IonText>
      {isWaiver && <WaiverStatusSection setIsOpen={setIsOpen} />}
      {!isWaiver && <Ho4StatusSection resident={resident} policyNumber={resident?.policy_number} color={color} />}

      {/*ISSUE*/}
      <span className="small-grey-separator"></span>

      <UniversalButton
        onClick={onClick}
        value={'BUTTON.VIEW_DETAILS'}
      />

    </StatusSection>
  )
}
