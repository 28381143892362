import {
  ConfigsActionTypes,
  ConfigsReducerType,
  CONFIGS_INIT_STATE,
  ADD_LOGIN_BUTTON,
  REMOVE_LOGIN_BUTTON,
  ADD_LOGOUT_BUTTON,
  REMOVE_LOGOUT_BUTTON
} from "./types";

export default function configsReducer(
  state: ConfigsReducerType = CONFIGS_INIT_STATE,
  action: ConfigsActionTypes,
): ConfigsReducerType {
  switch (action.type) {
    case ADD_LOGIN_BUTTON:
      return action.configs
    case REMOVE_LOGIN_BUTTON:
      return action.configs
    case ADD_LOGOUT_BUTTON:
      return action.configs
    case REMOVE_LOGOUT_BUTTON:
      return action.configs
    default:
      return state;
  }
}
